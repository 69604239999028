import Vue from 'vue';
import VueMapbox from 'vue-mapbox';
import Mapbox from 'mapbox-gl';
import Toasted from 'vue-toasted';
import VueExcelEditor from 'vue-excel-editor';

Vue.use(VueMapbox, { mapboxgl: Mapbox });

Vue.use(Toasted, { position: 'bottom-left', duration: 5000 });

Vue.use(VueExcelEditor);

// tslint:disable-next-line:no-var-requires
const vueUploadComponent = require('vue-upload-component');
Vue.use(vueUploadComponent);
