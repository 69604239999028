import Vue from 'vue';
import axios from 'axios';

export const getAvailableMarkets = ({ commit }, data) =>
    axios
        .post('markets/getAvailableMarkets', data)
        .then((res) => {
            commit('setAvailableMarkets', res.data.availableMarkets);
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const getMarkets = ({ commit }, data) =>
    axios
        .post('markets/getMarkets', data)
        .then((res) => {
            commit('paginationToolbar/setTotalPages', res.data.pagesCount, { root: true });
            commit('setMarkets', res.data.markets);
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

// export const getMarket = ({ commit }, data) =>
//   axios
//     .post('markets/getMarket', data)
//     .then((res) => {
//       // commit('setMarket', res.data.market);
//     })
//     .catch((err) => {
//
//     });

export const copyMarket = ({}, data) =>
    axios
        .post('markets/copyMarket', data)
        .then((res) => {
            return res.data.marketId;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const getUsers = ({ commit }, data) =>
    axios
        .post('markets/getUsers', data)
        .then((res) => {
            commit('setUsers', res.data.users);
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const checkUserPermissions = ({}, data) =>
    axios
        .post('markets/checkUserPermissions', data)
        .then((res) => {
            return res.data.userPermissions;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

// export const getMarketContributors = ({ commit }, data) =>
//   axios
//     .post('markets/getMarketContributors', data)
//     .then((res) => {
//       commit('setMarketContributors', res.data.marketContributors);
//     })
//     .catch((err) => {
//
//     });

export const shareMarket = ({}, data) =>
    axios
        .post('markets/shareMarket', data)
        .then(() => {
            return true;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const removeMarketContributor = ({}, data) =>
	axios
		.post('markets/removeMarketContributor', data)
		.then(() => {
			return true;
		})
		.catch((err) => {
			if (err) {
				Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
			}
		});

export const removeArchiveEditData = ({}, data) =>
	axios
		.delete(`markets?${data.query_string}`)
		.then(() => {
			location.reload();
			return true;
		})
		.catch((err) => {
			if (err) {
				Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
			}
		});

// export const getMarketStatuses = ({ commit }, data) =>
//   axios
//     .post('markets/getMarketStatuses', data)
//     .then((res) => {
//       commit('setMarketStatuses', res.data.marketStatuses);
//     })
//     .catch((err) => {
//
//     });

export const changeMarketStatus = ({}, data) =>
    axios
        .post('markets/changeMarketStatus', data)
        .then(() => {
			location.reload();
			return true;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });
