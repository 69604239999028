<template>
    <v-footer class="footer" height="40">
        <v-row>
            <v-col cols="12" class="d-flex align-center">Copyright&nbsp;&copy;&nbsp;{{ new Date().getFullYear() }}&nbsp;KillerBee</v-col>
        </v-row>
    </v-footer>
</template>

<script>
export default {
    data() {
        return {};
    },
    methods: {},
};
</script>
