import Vue from 'vue';
import axios from 'axios';

export const getPlantTypes = ({ commit }) => axios.post('generalSettings/getPlantTypes').then((res) => commit('setPlantTypes', res.data.plantTypes));

export const getGeologies = ({ commit }) => axios.post('generalSettings/getGeologies').then((res) => commit('setGeologies', res.data.geologyTypes));

export const getApplications = ({ commit }) =>
    axios.post('aggregatesProducers/getApplications', { marketId: Number(localStorage.getItem('marketId')) }).then((res) => commit('setApplications', res.data.applications));

export const getProducersGroups = ({ commit }) =>
    axios.post('aggregatesProducers/getGroups', { marketId: Number(localStorage.getItem('marketId')) }).then((res) => commit('setProducersGroups', res.data.groups));

export const getCustomersGroups = ({ commit }) =>
    axios.post('customers/getGroups', { marketId: Number(localStorage.getItem('marketId')) }).then((res) => {
        commit('setCustomersGroups', res.data.groups);
    });

export const getAggregatesProducerCompanies = ({ commit }, data) =>
    axios.post('aggregatesProducers/getAggregatesProducerCompanies', data).then((res) => commit('setAggregatesProducerCompanies', res.data.companies));

export const getCustomerCompanies = ({ commit }, data) => axios.post('customers/getCustomerCompanies', data).then((res) => commit('setCustomerCompanies', res.data.companies));

export const getMarketSegments = ({ commit }) =>
    axios.post('customers/getMarketSegments', { marketId: Number(localStorage.getItem('marketId')) }).then((res) => {
        commit('setMarketSegments', res.data.marketSegments);
    });
