<template>
    <router-view />
</template>

<script>
import axios from 'axios';
import { mapGetters, mapMutations, mapActions } from 'vuex';


const MARKET_ID = 'marketId';

export default {
    name: 'Main',
    data() {
        return {};
    },
    mounted() {
        this.init();
    },
    computed: {
        ...mapGetters('paginationToolbar', {
            page: 'page',
        }),
        ...mapGetters('marketAssessment', {
            availableMarkets: 'availableMarkets',
            market: 'market',
        }),
    },
    methods: {
        ...mapMutations('authenticate', {
            setAuthenticateSource: 'setAuthenticateSource',
        }),
        ...mapMutations('marketAssessment', {
            setIsLoadedAvailableMarkets: 'setIsLoadedAvailableMarkets',
            setMarket: 'setMarket',
        }),
        //...mapActions('account', {
        //    getUserRole: 'getUserRole',
        //}),
        ...mapActions('marketAssessment', {
            getAvailableMarkets: 'getAvailableMarkets',
        }),
        async init() {
            //const getUserRole = this.getUserRole();
            const availableMarkets = this.getAvailableMarkets();
            //await Promise.all([getUserRole, availableMarkets]);
            await Promise.all([availableMarkets]);

           
            if (MARKET_ID in localStorage) {
                const marketId = localStorage.getItem(MARKET_ID);
                const market = this.availableMarkets.find((item) => {
                    return item.id.toString() === marketId;
                });
                if (market) {
                    this.setMarket(market);
                } else {
                    if (this.availableMarkets.length) {
                        this.setMarket(this.availableMarkets[0]);
                    }
                }
            } else {
                if (this.availableMarkets.length) {
                    this.setMarket(this.availableMarkets[0]);
                }
            }
            const source = axios.CancelToken.source();
            this.setAuthenticateSource(source);
            this.setIsLoadedAvailableMarkets(true);
        },
    },
};
</script>

<style lang="scss" scoped></style>
