import Vue from 'vue';
import axios from 'axios';

export const getAggregatesProducersRate = ({ commit }) =>
	axios
		.get(`CompletionRate/${Number(localStorage.getItem('marketId'))}/GetAgregatesProduccerRate`)
		.then((res) => {
			commit('setProducersRate', res.data);
		})
		.catch((err) => {
			if (err) {
				Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
			}
		});


export const getPlantTypes = ({ commit }) =>
    axios
        .post('aggregatesProducers/getPlantTypes', { marketId: Number(localStorage.getItem('marketId')) })
        .then((res) => commit('setPlantTypes', res.data.plantTypes))
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const getGroups = ({ commit }) =>
    axios
        .post('aggregatesProducers/getGroups', { marketId: Number(localStorage.getItem('marketId')) })
        .then((res) => commit('setGroups', res.data.groups))
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const getGeologies = ({ commit }) =>
    axios
        .post('aggregatesProducers/getGeologies', { marketId: Number(localStorage.getItem('marketId')) })
        .then((res) => commit('setGeologies', res.data.geologies))
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const getApplications = ({ commit }) =>
    axios
        .post('aggregatesProducers/getApplications', { marketId: Number(localStorage.getItem('marketId')) })
        .then((res) => commit('setApplications', res.data.applications))
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const getProductTypes = ({ commit }) =>
    axios
        .post('aggregatesProducers/getProductTypes', { marketId: Number(localStorage.getItem('marketId')) })
        .then((res) => commit('setProductTypes', res.data.productTypes))
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const getTransportTypes = ({ commit }) =>
    axios
        .post('aggregatesProducers/getTransportTypes', { marketId: Number(localStorage.getItem('marketId')) })
        .then((res) => commit('setTransportTypes', res.data.transportTypes))
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const getTags = ({ commit }) =>
    axios
        .post('aggregatesProducers/GetTags')
        .then((res) => commit('setTags', res.data.tags))
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const getAggregatesProducers = ({ commit }, data) =>
    axios
        .post('aggregatesProducers/getAggregatesProducers', data)
        .then((res) => {
            commit('setAggregatesProducers', res.data.aggregatesProducers);
            commit('paginationToolbar/setTotalPages', res.data.pagesCount, { root: true });
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const removeAggregatesProducers = ({ commit }, data) =>
    axios
        .post('aggregatesProducers/RemoveAggregatesProducer', data)
        .then((res) => {
            Vue.toasted.success('Aggregates Producers removed success');
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const copyAggregatesProducer = ({}, data) =>
    axios
        .post('aggregatesProducers/CopyAggregatesProducer', data)
        .then((res) => {
            return res.data.aggregatesProducerId;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const importAggregateProducers = ({}, data) =>
	axios
		.post('aggregatesProducers/importAggregateProducers', data)
		.then((res) => {
			return true;
		})
		.catch((err) => {
			if (err) {
				Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
			}
		});


export const resetPercentageSplit = ({}, data) =>
	axios
		.post('aggregatesProducers/resetpercentagesplit', data)
		.then((res) => {
			return true;
		})
		.catch((err) => {
			if (err) {
				Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
			}
		});
