import axios from 'axios';

export const getAggregatesProducerAddresses = ({ commit }, data) =>
    axios
        .post('aggregatesProducers/getAggregatesProducerAddresses', data)
        .then((res) => commit('setAggregatesProducerAddresses', res.data.addresses));

export const getCustomerAddresses = ({ commit }, data) =>
    axios.post('customers/getCustomerAddresses', data).then((res) => commit('setCustomerAddresses', res.data.addresses));

export const getCustomerPlantNames = ({ commit }, data) =>
    axios.post('customers/getCustomerPlantNames', data).then((res) => commit('setCustomerPlantNames', res.data.plantNames));
