import Vue from 'vue';
import axios from 'axios';

export const saveMarket = ({ commit }, form) => axios.post('GeneralSettings/SaveMarket', form).then((res) => commit('setMarketId', res.data.marketId));

export const saveMarketBoundary = ({ commit }, boundary) => axios.post('generalSettings/saveMarketBoundary', boundary).then((res) => console.log('saveMarketBoundary', res));
export const getPlantTypes = ({ commit }) => axios.post('generalSettings/getPlantTypes').then((res) => commit('getPlantTypes', res.data.plantTypes));
export const getGeologies = ({ commit }) => axios.post('generalSettings/getGeologies').then((res) => commit('getGeologies', res.data.geologyTypes));
export const getSaveNewGeology = ({ commit }, geology) =>
    axios.post('generalSettings/addGeologySubType', geology).then((res) => commit('getSaveNewGeology', res.data.geologySubType));
export const getTransportTypes = ({ commit }) => axios.post('generalSettings/getTransportTypes').then((res) => commit('getTransportTypes', res.data.transportTypes));
export const addGroupToMarket = ({ commit }, val) =>
    axios
        .post('generalSettings/addGroupToMarket', { marketId: Number(localStorage.getItem('marketId')), name: val.name, isMyGroup: val.isMyGroup })
        .then((res) => commit('setGroupMarketId', res.data.marketGroupId));
export const updateGroup = ({ commit }, field) => axios.post('generalSettings/updateGroup', field).then((res) => commit('setUpdateMarketName', res.data));
export const removeGroupMarket = ({ commit }, marketGroupId) =>
    axios.post('generalSettings/removeGroup', { marketGroupId }).then(() => commit('getRemoveGroupMarket', marketGroupId));

export const savePlantTypes = ({ commit }, ids) =>
    axios.post('generalSettings/savePlantTypes', { marketId: Number(localStorage.getItem('marketId')), plantTypes: ids }).then((res) => console.log('savePlantTypes'));

export const saveGeology = ({ commit }, geology) => axios.post('generalSettings/saveGeologies', geology).then((res) => console.log('saveGeologies'));
export const getRemoveGeologySubType = ({ commit }, data) =>
    axios
        .post('generalSettings/removeGeologySubType', { marketId: Number(localStorage.getItem('marketId')), geologySubTypeId: data.subOptionid })
        .then(() => commit('getRemoveGeologySubType', data));
export const getProductTypes = ({ commit }) => axios.post('generalSettings/getProductTypes').then((res) => commit('getProductTypes', res.data.productTypes));

export const saveProductType = ({ commit }, data) => axios.post('generalSettings/saveProductType', data).then((res) => console.log('saveProductType'));

export const addTransportMode = ({ commit }, form) =>
    axios.post('generalSettings/addTransportMode', form).then((res) => {
        commit('addTransportMode', res.data.marketTransortModeId);
        return res.data.marketTransortModeId;
    });

export const updateTransportMode = ({ commit }, form) => axios.post('generalSettings/updateTransportMode', form).then((res) => console.log('updateTransportMode'));

export const removeTransportMode = ({ commit }, field) =>
    axios.post('generalSettings/removeTransportMode', field).then(() => commit('removeTransportMode', field.marketTransportModeId));
export const getCurrencies = ({ commit }) => axios.post('generalSettings/getCurrencies').then((res) => commit('getCurrencies', res.data.currencies));
export const getMetrics = ({ commit }) => axios.post('generalSettings/getMetrics').then((res) => commit('getMetrics', res.data.metrics));
export const saveSettings = ({ commit }, marketId) => axios.post('generalSettings/saveSettings', marketId);

export const getMarket = ({ commit }, data) =>
    axios
        .post('markets/getMarket', data)
        .then((res) => {
            commit('setMarket', res.data.market);
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const saveMarketCurrencyAndNumberFormat = ({ commit }, data) =>
    axios
        .post('generalSettings/saveMarketCurrencyAndNumberFormat', data)
        .then(() => {
            commit('marketAssessment/updateMarketCurrencyAndNumberFormat', data, { root: true });
            return true;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const saveMarketMetric = ({ commit }, data) => {
    return axios
        .post('generalSettings/saveMarketMetric', { marketId: data.marketId, metricId: data.metric.id })
        .then(() => {
            commit('marketAssessment/updateMarketMetric', data.metric, { root: true });
            return true;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });
    };
