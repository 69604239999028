<template>
	<div
		v-if="isDisplayPaginationToolbar && !isDisplayEditDataToolbar"
		v-bind:style= "[isOpenClosePaginationToolbar ? {'right':'24px'} : {'right':'0px'}]"
		style="z-index: 999; height: 60px; position: fixed; bottom: 24px; transition: .2s all; background-color: #0e3f66; border-radius: 5px; display: flex; align-items: center"
	>
		<v-btn
			@click="isOpenClosePaginationToolbar = !isOpenClosePaginationToolbar"
			small text
			class="close-open-pagination"
		>
			<img
				:style="[!isOpenClosePaginationToolbar ? {'transform': 'rotate(180deg)'} : {'transform': 'rotate(0deg)'}]"
				src="@/assets/pagination_toolbar/arrow_open_close.svg">
		</v-btn>
		<v-container
			v-if="isOpenClosePaginationToolbar"
			style="width: 700px;"
		>
			<v-row no-gutters>
				<v-col cols="3" style="display: flex; align-items: center; justify-content: start;">
					<span style="white-space: nowrap; font-size: 15px; padding-right: 12px; color: #8798ad;">Rows per page:</span>
					<template v-if="pageSize === null">
						<v-progress-circular color="#ff893a" indeterminate></v-progress-circular>
					</template>
					<template v-else>
						<v-select
							@change="onPageSize"
							:value="pageSize"
							:items="pageSizeSteps"
							:disabled="isLoadingPaginationToolbar"
							style="max-width: 60px;"
							color="regalBlue"
							item-color="regalBlue"
							class="pagination-toolbar-selection"
							hide-details
							dense
						>
						</v-select>
					</template>
				</v-col>
				<v-col cols="2" style="display: flex; align-items: center; justify-content: center; color: #ffffff;">
					<template v-if="totalPages === null">
						<v-progress-circular color="#ff893a" indeterminate></v-progress-circular>
					</template>
					<template v-else>
          <span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
            {{ page }} <template v-if="totalPages > 0"> of {{ totalPages }} </template>
          </span>
					</template>
				</v-col>
				<v-col cols="1" style="display: flex; align-items: center; justify-content: center;">
					<v-btn @click="previousPage()" :disabled="page === 1 || isLoadingPaginationToolbar" color="#ff893a" x-small fab text>
						<img
							v-if="page === 1 || isLoadingPaginationToolbar"
							src="@/assets/pagination_toolbar/arrow_previous_disabled.svg"
							style="width: 12px; margin-right: 2px;"
						/>
						<img v-else src="@/assets/pagination_toolbar/arrow_previous.svg" style="width: 12px; margin-right: 2px;" />
					</v-btn>
				</v-col>
				<v-col cols="1" style="display: flex; align-items: center; justify-content: center;">
					<v-btn
						@click="nextPage()"
						:disabled="totalPages === null || totalPages === 0 || page === totalPages || isLoadingPaginationToolbar"
						color="#ff893a"
						x-small
						fab
						text
					>
						<img
							v-if="totalPages === null || totalPages === 0 || page === totalPages || isLoadingPaginationToolbar"
							src="@/assets/pagination_toolbar/arrow_next_disabled.svg"
							style="width: 12px; margin-left: 2px;"
						/>
						<img v-else src="@/assets/pagination_toolbar/arrow_next.svg" style="width: 12px; margin-left: 2px;" />
					</v-btn>
				</v-col>
				<v-col cols="1" style="display: flex; align-items: center; justify-content: center;">
					<v-tooltip content-class="regalBlue--text" color="ghostWhite" top>
						<template v-slot:activator="{ on, attrs }">
							<img v-bind="attrs" v-on="on" src="@/assets/icons/copy_or_share.svg" style="width: 25px" class="cursor-pointer" />
						</template>
						<span>In development</span>
					</v-tooltip>
				</v-col>
				<v-col cols="4">
					<v-btn
						@click="onAdd()"
						:style="{ opacity: isLoadingPaginationToolbar && !isDisabledPaginationToolbarAddDataButton ? 0.3 : 1 }"
						:class="{ 'pointer-events-none': isLoadingPaginationToolbar }"
						:disabled="isDisabledPaginationToolbarAddDataButton"
						:loading="isLoadingPaginationToolbarAddData"
						color="#ff893a"
						block
						dark
					>
						<img src="@/assets/icons/plus.svg" style="width: 16px" />
						<span style="padding-left: 8px; color: #ffffff">ADD NEW</span>
					</v-btn>
				</v-col>
			</v-row>
		</v-container>

	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

const PAGE_SIZE = 'pageSize';

export default {
  name: 'PaginationToolbar',
  data() {
    return {
		isOpenClosePaginationToolbar: true
	};
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters('paginationToolbar', {
      defaultPageSize: 'defaultPageSize',
      pageSizeSteps: 'pageSizeSteps',
      pageSize: 'pageSize',
      page: 'page',
      totalPages: 'totalPages',
      isDisplayPaginationToolbar: 'isDisplayPaginationToolbar',
      isLoadingPaginationToolbar: 'isLoadingPaginationToolbar',
      isLoadingPaginationToolbarAddData: 'isLoadingPaginationToolbarAddData',
      isDisabledPaginationToolbarAddDataButton: 'isDisabledPaginationToolbarAddDataButton',
    }),
    ...mapGetters('editDataToolbar', {
      isDisplayEditDataToolbar: 'isDisplayEditDataToolbar',
    }),
  },
  methods: {
    ...mapMutations('paginationToolbar', {
      triggerAddData: 'triggerAddData',
      setPageSize: 'setPageSize',
      nextPage: 'nextPage',
      previousPage: 'previousPage',
    }),
    ...mapActions('paginationToolbar', {
      getPageSize: 'getPageSize',
      updatePageSize: 'updatePageSize',
    }),
    async init() {
      const pageSize = await this.getPageSize();
      if (PAGE_SIZE in localStorage) {
        const storedPageSize = localStorage.getItem(PAGE_SIZE);
        if (this.pageSizeSteps.includes(parseInt(storedPageSize, 15))) {
          this.setPageSize(pageSize, 15);
        } else {
          this.setPageSize(pageSize);
        }
      } else {
        this.setPageSize(pageSize);
      }
    },
    onPageSize(data) {
      this.updatePageSize({ pageSize: data }).then((res) => {
        if (res) {
          this.setPageSize(data);
        }
      });
    },
    onAdd() {
      this.triggerAddData();
    },
  },
};
</script>

<style lang="scss" scoped>
.close-open-pagination{
	background-color: rgba(255, 255, 255, 0.12);
	height: 100% !important;
}
</style>
