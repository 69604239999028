export const isDisplayPaginationToolbar = (state) => state.isDisplayPaginationToolbar;
export const isLoadingPaginationToolbar = (state) => state.isLoadingPaginationToolbar;
export const isLoadingPaginationToolbarAddData = (state) => state.isLoadingPaginationToolbarAddData;
export const isDisabledPaginationToolbarAddDataButton = (state) => state.isDisabledPaginationToolbarAddDataButton;
export const triggerAddData = (state) => state.triggerAddData;
export const defaultPageSize = (state) => state.defaultPageSize;
export const pageSizeSteps = (state) => state.pageSizeSteps;
export const pageSize = (state) => state.pageSize;
export const page = (state) => state.page;
export const totalPages = (state) => state.totalPages;
