<template>
    <router-view />
</template>

<script>
export default {
    name: 'App',
    mounted() {
        const currentBrowser = this.checkBrowser();
        if ((currentBrowser.name === 'Chrome' && currentBrowser.version >= 54) || (currentBrowser.name === 'Safari' && currentBrowser.version >= 11)) {
            if (window.location.pathname === '/update-browser') {
                this.$router.push({ path: '/' });
            }
        } else {
            this.$router.push({ name: 'updateBrowser' });
        }
    },
    methods: {
        checkBrowser() {
            let ua = navigator.userAgent,
                tem,
                M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
            if (/trident/i.test(M[1])) {
                tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
                return {
                    name: 'IE',
                    version: tem[1] || '',
                };
            }
            if (M[1] === 'Chrome') {
                tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
                if (tem != null) {
                    return {
                        name: tem[1].replace('OPR', 'Opera'),
                        version: tem[2],
                    };
                }
            }
            M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
            if ((tem = ua.match(/version\/(\d+)/i)) != null) {
                M.splice(1, 1, tem[1]);
            }
            return {
                name: M[0],
                version: parseInt(M[1]),
            };
        },
    },
};
</script>

<style lang="scss">
@import './styles/main';
</style>
