import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

const CURRENT_TAB = 'currentTab';
const PREVIOUS_TAB = 'previousTab';

const state = {
  currentTab: localStorage.getItem(CURRENT_TAB)
    ? parseInt(localStorage.getItem(CURRENT_TAB), 10)
    : 0,
  previousTab: localStorage.getItem(PREVIOUS_TAB)
    ? parseInt(localStorage.getItem(PREVIOUS_TAB), 10)
    : 0,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
