import store from '@/store';
import {UserRole} from "@/core/enums/userRole";

export const checkUserRole = (checkPermissionsArray, callback) => {
	const permissionsCheckers = {
		checkMarketOwner: () => {
			const isMarketOwner = store.getters['account/userMarketIds'].includes(Number(localStorage.getItem('marketId')));
			if (store.getters['account/userRole'] !== UserRole.CompanyAdmin && store.getters['account/userRole'] !== UserRole.Administrator&& store.getters['account/userRole'] !== UserRole.User && !isMarketOwner) {
				return '/dashboard';
			}
			return null;
		}

	}
	return async (to, from, next) => {


		await store.dispatch('account/getUserRole')

		if (Array.isArray(checkPermissionsArray) && checkPermissionsArray.length) {
			for (let permission of checkPermissionsArray) {
				if (permissionsCheckers[permission]) {
					const nextRoute = permissionsCheckers[permission]()
					if (nextRoute) {
						return next(nextRoute)
					}
				}
			}
		}

		if (callback) {
			return callback(to, from, next)
		} else {
			return next();
		}
	}
};
