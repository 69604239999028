/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
// import { selectedOptionsById } from '../../utils/selectedOption';
// import selectPlantTypeOptionById from '../../utils/multiselct';

// eslint-disable-next-line import/prefer-default-export
export const setPlantTypes = (state, payload) => {
    state.plantTypes = payload;
};

export const setGroups = (state, payload) => {
    state.groups = payload;
};

export const setGeologies = (state, payload) => {
    state.geologies = payload;
};

export const setApplications = (state, payload) => {
    state.applications = payload;
};

export const setProductTypes = (state, payload) => {
    state.productTypes = payload;
};

export const setAggregatesProducers = (state, payload) => {
    state.aggregatesProducers = payload;
};

export const setTransportTypes = (state, payload) => {
    state.transportTypes = payload;
};

export const setTags = (state, payload) => {
    state.tags = payload;
};

export const setCompanies = (state, companies) => {
    state.customers = companies;
};

export const setShowAddProducer = (state, payload) => {
	state.showAddProducer = payload;
};

// export const setProducersRate = (state) => {
// 	state.producersRate;
// };
export const setProducersRate = (state, producersRate) => {
	state.producersRate = producersRate;
};
