const PAGE_SIZE = 'pageSize';

export const initPagination = (state) => {
  state.page = 1;
  state.totalPages = null;
};

export const nextPage = (state) => {
  if (state.page + 1 <= state.totalPages) {
    state.page++;
  }
};

export const previousPage = (state) => {
  if (state.page - 1 >= 1) {
    state.page--;
  }
};

export const setIsDisplayPaginationToolbar = (state, isDisplayPaginationToolbar) => {
  state.isDisplayPaginationToolbar = isDisplayPaginationToolbar;
};

export const setIsLoadingPaginationToolbar = (state, isLoadingPaginationToolbar) => {
  state.isLoadingPaginationToolbar = isLoadingPaginationToolbar;
};

export const setIsLoadingPaginationToolbarAddData = (state, isLoadingPaginationToolbarAddData) => {
  state.isLoadingPaginationToolbarAddData = isLoadingPaginationToolbarAddData;
};

export const setIsDisabledPaginationToolbarAddDataButton = (state, isDisabledPaginationToolbarAddDataButton) => {
  state.isDisabledPaginationToolbarAddDataButton = isDisabledPaginationToolbarAddDataButton;
};

export const setPageSize = (state, pageSize) => {
  if (pageSize === null) {
    localStorage.removeItem(PAGE_SIZE);
  } else {
    localStorage.setItem(PAGE_SIZE, pageSize);
  }
  state.pageSize = pageSize;
};

export const setTotalPages = (state, totalPages) => {
  state.totalPages = totalPages;
};

export const triggerAddData = (state) => {
  state.triggerAddData = !state.triggerAddData;
};
