import Vue from 'vue';
import axios from 'axios';

export const getMapAggregatesProducers = ({ commit }, data) =>
    axios
        .post('aggregatesProducers/getMapAggregatesProducers', data)
        .then((res) => commit('setMapAggregatesProducers', res.data.aggregatesProducers))
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const getMapCustomers = ({ commit }, data) =>
    axios
        .post('customers/getMapCustomers', data)
        .then((res) => commit('setMapCustomers', res.data.customerPlants))
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });
