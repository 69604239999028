export const getPlantType = (state) => state.plantType;
export const getGeologies = (state) => state.geologies;
export const getApplications = (state) => state.applications;
export const getProducersGroups = (state) => state.producersGroups;
export const getCustomersGroups = (state) => state.customersGroups;
export const getAggregatesProducerCompanies = (state) => state.aggregatesProducerCompanies;
export const getCustomerCompanies = (state) => state.customerCompanies;
export const getMarketSegments = (state) => state.marketSegments;
// export const getFilters = (state) => state.getFilters;
export const getSelectedFilter = (state) => state.selectedFilters;
