const CURRENT_TAB = 'currentTab';
const PREVIOUS_TAB = 'previousTab';

export const setCurrentTab = (state, currentTab) => {
  localStorage.setItem(CURRENT_TAB, currentTab);
  state.currentTab = currentTab;
};

export const setPreviousTab = (state, previousTab) => {
  localStorage.setItem(PREVIOUS_TAB, previousTab);
  state.previousTab = previousTab;
};
