export const setDefaultOtherMarketSegments = (state, defaultOtherMarketSegments) => {
  state.defaultOtherMarketSegments = defaultOtherMarketSegments;
};

export const setOtherMarketSegments = (state, otherMarketSegments) => {
  state.otherMarketSegments = otherMarketSegments;
};

export const setAggregateProducers = (state, aggregateProducers) => {
  state.aggregateProducers = aggregateProducers;
};

export const concatAggregateProducers = (state, aggregateProducers) => {
  state.aggregateProducers = state.aggregateProducers.concat(aggregateProducers);
};
