const MARKET_ID = 'marketId';
const MARKET_NAME = 'marketName';

export const setAvailableMarkets = (state, availableMarkets) => {
  state.availableMarkets = availableMarkets;
};

export const setIsLoadedAvailableMarkets = (state, isLoadedAvailableMarkets) => {
  state.isLoadedAvailableMarkets = isLoadedAvailableMarkets;
};

export const pushAvailableMarkets = (state, availableMarket) => {
  state.availableMarkets.unshift(availableMarket);
};

export const setMarkets = (state, markets) => {
  state.markets = markets;
};

export const pushMarkets = (state, market) => {
  state.markets.unshift(market);
};

export const setMarket = (state, market) => {
  if (market === null) {
    localStorage.removeItem(MARKET_ID);
    localStorage.removeItem(MARKET_NAME);
  } else {
    localStorage.setItem(MARKET_ID, market.id);
    localStorage.setItem(MARKET_NAME, market.name);
  }
  state.market = market;
};

export const updateMarketCurrencyAndNumberFormat = (state, data) => {
  state.market.separatorType = data.separatorType;
  if (state.market.currency === null) {
    state.market.currency = { id: data.currencyId };
  } else {
    Object.assign(state.market.currency, {
      id: data.currencyId,
    });
  }
};

export const updateMarketMetric = (state, data) => {
  state.market.metric = data;
};

export const setUsers = (state, users) => {
  state.users = users;
};

// export const setMarketContributors = (state, marketContributors) => {
//   state.marketContributors = marketContributors;
// };

// export const setMarketStatuses = (state, marketStatuses) => {
//   state.marketStatuses = marketStatuses;
// };
