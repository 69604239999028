import Vuex from 'vuex';
import Vue from 'vue';
import location from './location';
import authenticate from './authenticate';
import restorePassword from './restorePassword';
import baseHeaderTab from './baseHeaderTab';
import generalSettings from './generalSettings';
import aggregatesProducers from './aggregatesProducers';
import marketSegment from './marketSegment';
import marketAssessment from './marketAssessment';
import geocoding from './geocoding';
import paginationToolbar from './paginationToolbar';
import editDataToolbar from './editDataToolbar';
import producersAndCustomersMap from './producersAndCustomersMap';
import supplier from './supplier';
import account from './account';
import competitor from './competitor';
import filters from './filters';
import importExcelDialog from './importExcelDialog';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        location,
        authenticate,
        restorePassword,
        baseHeaderTab,
        generalSettings,
        aggregatesProducers,
        marketSegment,
        marketAssessment,
        geocoding,
        paginationToolbar,
        editDataToolbar,
        producersAndCustomersMap,
        supplier,
        account,
        competitor,
        filters,
        importExcelDialog,
    },
});
