import { MarketSegmentType } from '@/core/enums/marketSegmentType';

const shapeIcon = (plantType) => {
  if (plantType) {
    const name = plantType.name.toLowerCase();
    const CIRCLE = ['inactive plant'];
    const SQUARES = ['quarry', 'sand pit'];
    const TRIANGLE = ['depot', 'terminal', 'wharf'];
    const PENTAGON = ['greenfield'];
    switch (true) {
      case CIRCLE.includes(name):
        return 'circle';
      case SQUARES.includes(name):
        return 'squares';
      case PENTAGON.includes(name):
        return 'pentagon';
      case TRIANGLE.includes(name):
        return 'triangle';
      default:
        return 'other';
    }
  } else {
    return 'no_plant_type';
  }
};

const colorIcon = (producerGeologies) => {
  // if (producerGeologies) {
  //     const shortName = producerGeologies.primaryApplication.application.shortName;
  //     if (shortName === 'P&B' || shortName === 'n/a') {
  //         return 'black'
  //     }
  // }
  if (producerGeologies && producerGeologies.geologySubType) {
    const geologyTypeId = producerGeologies.geologySubType.geologyTypeId || 0;
    switch (geologyTypeId) {
      case 1:
        return 'mangoTango';
      case 2:
        return 'darkLiver';
      case 3:
        return 'greeBlue';
      case 4:
        return 'crayolaGreen';
      default:
        return 'black';
    }
  }
};

const primaryApplication = (application) => {
  const applicationName = application.shortName ? application.shortName.toLowerCase() : '';
  switch (applicationName) {
    case 'a':
      return 'bordeaux';
    case 'c':
      return 'lighter_grey';
    case 'p&b':
      return 'pb_app';
    case 'a&c':
      return 'bordeaux_lighter_grey';
    default:
      return 'n/a';
  }
};

const choiceOfIconStyle = (item) => {
  const producerGeologies = item.aggregatesProducerGeologies[0];
  const style = [
    shapeIcon(item.plantType),
    item.plantType && item.plantType.id === 8 ? 'black' : item.plantType && item.plantType.id === 4 ? 'green' : colorIcon(producerGeologies),
    primaryApplication(producerGeologies ? producerGeologies.primaryApplication.application : ''),
  ];
  return style;
};

const colorCustomerSegment = (id) => {
  switch (id) {
    case 2:
      return 'red';
    case 3:
      return 'blue';
    case 4:
      return 'bordx';
    case 5:
      return 'darkslategray';
    default:
      return 'teal';
  }
};

export const iconColor = (segment) => {
  return ['circle', colorCustomerSegment(segment.id)];
};

export const iconImg = (id) => {
  switch (id) {
    case 2:
      return './map/icons/rails.svg';
    case 3:
      return './map/icons/concrete.svg';
    case 4:
      return './map/icons/asphalt.svg';
    case 5:
      return './map/icons/rmc.svg';
    default:
      return './map/icons/hard_hat.svg';
  }
};

export const buildProducers = (aggregates, type) => {
  const result = aggregates.map((item) => {
    return {
      ...item,
      type,
      popup: {
        name: item.name,
        groupName: item.group ? item.group.name : '',
        selfSupply: item.aggregatesProducerGeologies && item.aggregatesProducerGeologies.length ? item.aggregatesProducerGeologies[0].selfSupply : 0,
        annualProduction:
          item.aggregatesProducerGeologies && item.aggregatesProducerGeologies.length
            ? item.aggregatesProducerGeologies[0].annualAggregatesProduction
            : 0,
        totalProdVol:
          item.aggregatesProducerGeologies && item.aggregatesProducerGeologies.length
            ? item.aggregatesProducerGeologies[0].annualAggregatesProduction
            : 0,
        volSoldPer:
          item.aggregatesProducerGeologies && item.aggregatesProducerGeologies.length
            ? item.aggregatesProducerGeologies[0].volumeSoldInMarketPercent
            : 0,
        topCustomers: item.topCustomers ? item.topCustomers : null,
      },
      geology: item.aggregatesProducerGeologies[0].geologySubType,
      primaryApplication: item.aggregatesProducerGeologies[0].primaryApplication.application,
      coordinates: [item.latitude, item.longitude],
      iconStyle: choiceOfIconStyle(item).join(' '),
    };
  });
  return result;
};

export const buildCustomers = (aggregates, type) => {
  const calculateAbsoluteCoarseTon = (data) => {
    let coarseTon = 0;
    data.forEach((item) => {
      item.allocationOfProductTypes.forEach((allocation) => {
        if (allocation.productTypeId === 4) {
          coarseTon += allocation.volume;
        }
      });
    });
    return coarseTon;
  };
  const calculateAbsoluteFineTon = (data) => {
    let fineTon = 0;
    data.forEach((item) => {
      item.allocationOfProductTypes.forEach((allocation) => {
        if (allocation.productTypeId === 3) {
          fineTon += allocation.volume;
        }
      });
    });
    return fineTon;
  };
  const calculateAbsoluteFillBaseTon = (data) => {
    let fineTon = 0;
    data.forEach((item) => {
      item.allocationOfProductTypes.forEach((allocation) => {
        if (allocation.productTypeId === 2) {
          fineTon += allocation.volume;
        }
      });
    });
    return fineTon;
  };
  const calculateAbsoluteOtherTon = (data) => {
    let fineTon = 0;
    data.forEach((item) => {
      item.allocationOfProductTypes.forEach((allocation) => {
        if (allocation.productTypeId === 1) {
          fineTon += allocation.volume;
        }
      });
    });
    return fineTon;
  };
  const result = aggregates.map((item) => {
    return {
      ...item,
      type,
      popup: {
        name: item.name,
        company: item.company,
        groupName: item.group.name,
        selfSupply: item.selfSupply,
        annualProduction: item.annualProduction,
        totalProdVol:
          item.marketSegment.segment.marketSegmentType === MarketSegmentType.ReadyMixConcrete ||
          item.marketSegment.segment.marketSegmentType === MarketSegmentType.Asphalt
            ? item.marketSegment.tonsOfAggregatesPerUnit * item.annualProduction
            : item.annualProduction,
        volOfCoarse:
          item.marketSegment.segment.marketSegmentType === MarketSegmentType.ReadyMixConcrete ||
          item.marketSegment.segment.marketSegmentType === MarketSegmentType.Asphalt
            ? item.marketSegment.coarseAggregatesPercent
            : calculateAbsoluteCoarseTon(item.customerSuppliers) / (item.annualProduction / 100),
        volOfFine:
          item.marketSegment.segment.marketSegmentType === MarketSegmentType.ReadyMixConcrete ||
          item.marketSegment.segment.marketSegmentType === MarketSegmentType.Asphalt
            ? item.marketSegment.fineAggregatesPercent
            : calculateAbsoluteFineTon(item.customerSuppliers) / (item.annualProduction / 100),
        volOfFillBase:
          item.marketSegment.segment.marketSegmentType === MarketSegmentType.Custom
            ? calculateAbsoluteFillBaseTon(item.customerSuppliers) / (item.annualProduction / 100)
            : 0,
        volOfOther:
          item.marketSegment.segment.marketSegmentType === MarketSegmentType.Custom
            ? calculateAbsoluteOtherTon(item.customerSuppliers) / (item.annualProduction / 100)
            : 0,
        marketSegmentType: item.marketSegment.segment.marketSegmentType,
        topCustomerSuppliers: item.topCustomerSuppliers,
      },
      // geology: item.aggregatesProducerGeologies[0].geologySubType,
      coordinates: [item.latitude, item.longitude],
      iconStyle: iconColor(item.marketSegment.segment).join(' '),
      iconImg: iconImg(item.marketSegment.segment.id),
    };
  });
  return result;
};
