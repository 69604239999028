import axios from 'axios';
import store from '@/store/index';
import router from '@/router/index';

axios.defaults.baseURL = process.env.VUE_APP_HOST_URL;
axios.interceptors.request.use(
  (config) => {
    config.headers.common.Authorization = store.getters['authenticate/token'];
    if (store.getters['authenticate/authenticateSource']) {      
      return { ...config, cancelToken: store.getters['authenticate/authenticateSource'].token };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      if (router.currentRoute.name !== 'login') {
        if (error.response.status === 401) {
          store.commit('authenticate/setToken', null, { root: true });
          router.push({ name: 'login' });
        }
      }
    }
    return Promise.reject(error.response);
  },
);
