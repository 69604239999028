import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

const state = {
    aggregates: [],
    filteredAggregates: null,
    producersLength: 0,
    customersLength: 0,
    aggregatesProducers: [],
    aggregatesCustomers: [],
    filteredAggregatesProducers: null,
    filteredAggregatesCustomers: null,
    filterCount: null,
	selectedMarket: null,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
