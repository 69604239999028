export const getIndexTabByRouteName = (routerName) => {
    switch (routerName) {
        case 'marketAssessment':
            return 0;
        case 'dashboard':
            return 2;
        case 'generalSettings':
            return 3;
        case 'aggregateProducers':
            return 4;
        case 'marketSegments':
            return 5;
        case 'administrationUsers':
            return 6;
        case 'administrationCompanies':
            return 7;
        default:
            return 0;
    }
};
