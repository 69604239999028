export const setDefaultCompetitivePositionMarketSegments = (state, defaultCompetitivePositionMarketSegments) => {
  state.defaultCompetitivePositionMarketSegments = defaultCompetitivePositionMarketSegments;
};

export const setCompetitivePositionMarketSegments = (state, competitivePositionMarketSegments) => {
  state.competitivePositionMarketSegments = competitivePositionMarketSegments;
};

export const setCompetitorProducers = (state, competitorProducers) => {
  state.competitorProducers = competitorProducers;
};

export const concatCompetitorProducers = (state, competitorProducers) => {
  state.competitorProducers = state.competitorProducers.concat(competitorProducers);
};
