import Vue from 'vue';
import axios from 'axios';

export const getPageSize = ({}) =>
    axios
        .post('generalSettings/getPageSize')
        .then((res) => {
            return res.data.pageSize;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const updatePageSize = ({}, data) =>
    axios
        .post('generalSettings/setPageSize', data)
        .then(() => {
            return true;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });
