import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

const state = {
    plantType: null,
    geologies: null,
    applications: null,
    producersGroups: null,
    customersGroups: null,
    aggregatesProducerCompanies: null,
    getCustomerCompanies: null,
    marketSegments: null,
    selectedFilters: [],
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
