export const setUserRole = (state, userRole) => {
    state.userRole = userRole;
};

export const setCompanies = (state, companies) => {
    state.companies = companies;
};

export const setUsers = (state, users) => {
    state.users = users;
};

export const setUserMarketIds = (state, userMarketIds) => {
	state.userMarketIds = userMarketIds;
}

export const setUserOwnerMarketIds = (state, userMarketIds) => {
	state.userOwnerMarketIds = userMarketIds;
}

export const setUserContributorMarketIds = (state, userMarketIds) => {
	state.userContributorMarketIds = userMarketIds;
}

export const setSubscriptionStatus = (state, subscriptionStatus) => {
	state.subscriptionStatus = subscriptionStatus;
}
