import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
    theme: {
        options: {
            customProperties: true,
        },
        dark: false,
        white: '#ffffff',
        black: '#000000',
        themes: {
            light: {
				darkblue: '#000000',
				green: '#009c00',
                whiteLilac: '#f7f8fc',
                regalBlue: '#0e3f66',
                mangoTango: '#ff893a',
                rhino: '#264662',
                lilyWhite: '#e7f1fd',
				grayWhite: '#A6ABBD',
				paleSky: '#69707F',
                martinique: '#2e384d',
                casper: '#b0bac9',
                glitter: '#e2e9f9',
                antiFlashWhite: '#f2f5f8',
                bermudaGray: '#6e8ca3',
                bismark: '#4d6b82',
				bismarkBlue: '#DBEDFF',
				lavender: '#e0e7ff',
                pastelBlue: '#b0c3d2',
                сapeCod: '#404040',
                serenade: '#fff1e8',
                emerald: '#50bd82',
                terraCotta: '#e2675b',
                ghostWhite: '#f9faff',
                sazerac: '#ffede1',
                mistyRose: '#fee8e6',
                pastelRed: '#f7685b',
                ufoGreen: '2ed47a',
				crayolaGreen: '#20b363',
				activeGreen: '#2CD47A',
                greenBlue: '#1267aa',
                azureishWhite: '#dce8f2',
                burgundy: '#7e0027',
                bizarre: '#ecd9df',
                tara: '#e0f9eb',
                alto: '#d9d9d9',
                darkLiver: '#4f4f4f',
                // greeBlue: '#1164b4',
				greeBlue: '#0e3f66',
                ACbg: '#EBEBEB',
                concrete: '#EDEDED',
                aliceBlue: '#f0f8ff',
                mercury: '#e5e5e5',
                gallery: '#ededed',
                pattensBlue: '#e0f1ff',
                prussianBlue: '#0b3252',
                aero: '#79baee',
                supernova: '#ffc702',
                brilliantAzure: '#2ea2ff',
                rockBlue: '#9eb9d1',
                grayNurse: '#eaeaea',
                nickel: '#707070',
				disabledGray: '#9A9A9A',
                veniceBlue: '#0b538c',
            },
            dark: {
				darkblue: '#000000',
				green: '#009c00',
				whiteLilac: '#f7f8fc',
                regalBlue: '#0e3f66',
                mangoTango: '#ff893a',
                rhino: '#264662',
                lilyWhite: '#e7f1fd',
				grayWhite: '#A6ABBD',
                paleSky: '#69707F',
                martinique: '#2e384d',
                casper: '#b0bac9',
                glitter: '#e2e9f9',
                antiFlashWhite: '#f2f5f8',
                bermudaGray: '#6e8ca3',
                bismark: '#4d6b82',
				bismarkBlue: '#DBEDFF',
				lavender: '#e0e7ff',
                pastelBlue: '#b0c3d2',
                сapeCod: '#404040',
                serenade: '#fff1e8',
                emerald: '#50bd82',
                terraCotta: '#e2675b',
                ghostWhite: '#f9faff',
                sazerac: '#ffede1',
                mistyRose: '#fee8e6',
                pastelRed: '#f7685b',
                ufoGreen: '2ed47a',
                crayolaGreen: '#20b363',
				activeGreen: '#2CD47A',
				greenBlue: '#1267aa',
                azureishWhite: '#dce8f2',
                burgundy: '#7e0027',
                bizarre: '#ecd9df',
                tara: '#e0f9eb',
                alto: '#d9d9d9',
                darkLiver: '#4f4f4f',
                // greeBlue: '#1164b4',
				greeBlue: '#0e3f66',
				ACbg: '#EBEBEB',
                concrete: '#EDEDED',
                aliceBlue: '#f0f8ff',
                mercury: '#e5e5e5',
                gallery: '#ededed',
                pattensBlue: '#e0f1ff',
                prussianBlue: '#0b3252',
                aero: '#79baee',
                supernova: '#ffc702',
                brilliantAzure: '#2ea2ff',
                rockBlue: '#9eb9d1',
                grayNurse: '#eaeaea',
                nickel: '#707070',
				disabledGray: '#9A9A9A',
				veniceBlue: '#0b538c',
            },
        },
    },
});
