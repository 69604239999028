import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

const TOKEN = 'token';

const state = {
  token: localStorage.getItem(TOKEN),
  isAuthenticate: TOKEN in localStorage,
  authenticateSource: null,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
