/* eslint-disable import/prefer-default-export */
export const plantTypes = (state) => state.plantTypes;
export const groups = (state) => state.groups;
export const geologies = (state) => state.geologies;
export const applications = (state) => state.applications;
export const productTypes = (state) => state.productTypes;
export const aggregatesProducers = (state) => state.aggregatesProducers;
export const transportTypes = (state) => state.transportTypes;
export const tags = (state) => state.tags;
export const companies = (state) => state.companies;
export const showAddProducer = (state) => state.showAddProducer;
export const resetPercentageSplit = (state) => state.resetPercentageSplit;
export const producersRate = (state) => state.producersRate;
