import Vue from 'vue';
import axios from 'axios';

export const getMarketSegmentsRate = ({ commit }) =>

	axios
		.get(`CompletionRate/${Number(localStorage.getItem('marketId'))}/GetOverallMarketRate`)
		.then((res) => {
			commit('setSegmentsRate', res.data);
		})
		.catch((err) => {
			if (err) {
				Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
			}
		});

export const getLevelOfConfidence = ({ commit }) =>

	axios
		.get(`CompletionRate/${Number(localStorage.getItem('marketId'))}/GetLevelOfConfidence`)
		.then((res) => {
			commit('setLevelOfConfidence', res.data);
		})
		.catch((err) => {
			if (err) {
				Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
			}
		});

export const getOverallMarketSegmentsRate = ({ commit }) =>

	axios
		.get(`CompletionRate/${Number(localStorage.getItem('marketId'))}/GetOverallMarketSegmentRate`)
		.then((res) => {
			commit('setOverallSegmentsRate', res.data);
		})
		.catch((err) => {
			if (err) {
				Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
			}
		});

export const getMarketSegments = ({ commit }, data) =>
    axios
        .post('customers/getMarketSegments', data)
        .then((res) => {
			const marketSegments = res.data.marketSegments
			const otherSegments = marketSegments.find(item => item.segment.displayName === "Other segments");
			if (otherSegments) {
				const index = marketSegments.indexOf(otherSegments);
				marketSegments.splice(index, 1);
				marketSegments.push(otherSegments);
			}
			commit('setMarketSegments', marketSegments);
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const getCustomMarketSegmentNames = ({ commit }, data) =>
    axios
        .post('customers/getCustomMarketSegmentNames', data)
        .then((res) => {
            commit('setCustomMarketSegmentNames', res.data.marketSegmentNames);
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const saveMarketSegment = ({}, data) =>
    axios
        .post('customers/saveMarketSegment', data)
        .then(() => {
            return true;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const getCompanies = ({ commit }, data) =>
    axios
        .post('customers/getCompanies', data)
        .then((res) => {
            commit('setCompanies', res.data.companies);
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const searchCompanies = ({}, data) =>
    axios
        .post('customers/searchCompanies', data)
        .then((res) => {
            return res.data.companies;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const getGroups = ({ commit }, data) =>
    axios
        .post('customers/getGroups', data)
        .then((res) => {
            commit('setGroups', res.data.groups);
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const getPlantTypes = ({ commit }, data) =>
    axios
        .post('customers/getPlantTypes', data)
        .then((res) => {
            commit('setPlantTypes', res.data.plantTypes);
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const getTags = ({ commit }, data) =>
    axios
        .post('customers/getTags', data)
        .then((res) => {
            commit('setTags', res.data.tags);
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const getGeologies = ({ commit }, data) =>
    axios
        .post('customers/getGeologies', data)
        .then((res) => {
            commit('setGeologies', res.data.groups);
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const getProductTypes = ({ commit }, data) =>
    axios
        .post('customers/getProductTypes', data)
        .then((res) => {
            commit('setProductTypes', res.data.productTypes);
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const getAvailableSuppliers = ({ commit }, data) =>
    axios
        .post('customers/getAvailableSuppliers', data)
        .then((res) => {
            commit('setAvailableSuppliers', res.data.availableSuppliers);
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const saveCustomerSupplier = ({}, data) =>
    axios
        .post('customers/saveCustomerSupplier', data)
        .then((res) => {
            return res.data.customerSupplierId;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const importCustomerPlants = ({}, data) =>
    axios
        .post('customers/importCustomerPlants', data)
        .then((res) => {
            return true;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const removeCustomerSupplier = ({}, data) =>
    axios
        .post('customers/removeCustomerSupplier', data)
        .then(() => {
            return true;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const getCustomers = ({ commit }, data) =>
    axios
        .post('customers/getCustomers', data)
        .then((res) => {
            commit('paginationToolbar/setTotalPages', res.data.pagesCount, { root: true });
            commit('setCustomers', res.data.customers);
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const saveCustomerPlant = ({}, data) =>
    axios
        .post('customers/saveCustomerPlant', data)
        .then((res) => {
            return res.data.customerPlantId;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const removeCustomerPlant = ({}, data) =>
    axios
        .post('customers/removeCustomerPlant', data)
        .then(() => {
            return true;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const copyCustomerPlant = ({}, data) =>
    axios
        .post('customers/copyCustomerPlant', data)
        .then((res) => {
            return res.data.customerPlantId;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const createMarketSegment = ({}, data) =>
    axios
        .post('customers/createMarketSegment', data)
        .then((res) => {
            return res.data.marketSegment;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const deleteMarketSegment = ({}, data) =>
    axios
        .post('customers/deleteMarketSegment', data)
        .then(() => {
            return true;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const getAverageMarketPrices = ({}, data) =>
    axios
        .post('customers/getAverageMarketPrices', data)
        .then((res) => {
            return res.data.averageMarketPrices;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const saveAverageMarketPrice = ({}, data) =>
    axios
        .post('customers/saveAverageMarketPrice', data)
        .then((res) => {
            return res.data.averageMarketPriceId;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const getMarketsAttractiveness = ({}, data) =>
    axios
        .post('customers/getMarketsAttractiveness', data)
        .then((res) => {
            return res.data.marketsAttractiveness;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const saveMarketAttractiveness = ({}, data) =>
    axios
        .post('customers/saveMarketAttractiveness', data)
        .then((res) => {
            return res.data.marketAttractivenessId;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });
