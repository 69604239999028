import { buildProducers, buildCustomers } from '@/utils/buildProducersAndCustomers';

export const setFilteredAggregates = (state, aggregates) => {
    state.filteredAggregates = aggregates;
};

export const resetAggregates = (state) => {
    state.aggregates = [];
};

export const setMapAggregatesProducers = (state, payload) => {
    const type = 'producers';
    const aggregates = buildProducers(payload, type);
    state.aggregatesProducers = aggregates;
    state.aggregates = state.aggregates.concat(aggregates);
    aggregatesLength(state);
};

export const setMapCustomers = (state, payload) => {
    const type = 'customers';
    const aggregates = buildCustomers(payload, type);
    state.aggregatesCustomers = aggregates;
    state.aggregates = state.aggregates.concat(aggregates);
};

export const aggregatesLength = (state) => {
    let producersLength = 0;
    let customersLength = 0;
    const aggregates = state.filteredAggregates ? state.filteredAggregates : state.aggregates;
    aggregates.forEach((marker) => {
        if (marker.type === 'producers') {
            producersLength++;
        } else if (marker.type === 'customers') {
            customersLength++;
        }
    });
    state.producersLength = producersLength;
    state.customersLength = customersLength;
};

const filterAggregates = (state, aggregates, filters, filtered, filterLength) => {
    const selectedAggregates = filtered && filtered.length ? (filterLength > state.filterCount ? filtered : aggregates) : aggregates;
    const result = [];
    filters.forEach((params) => {
        selectedAggregates.forEach((marker) => {
            if (marker[params.type] && marker[params.type].id === params.option.id) {
                result.push(marker);
            }
        });
    });
    return result;
};

export const startFiltering = (state, filter) => {
    const newFiltersProducers = [];
    const newFiltersCustomers = [];
    const filterProducers = filter.filter((el) => el.aggregate === 'producers');
    const filterCustomers = filter.filter((el) => el.aggregate === 'customers');
    const typesProducers = filterProducers.map((item) => item.type);
    const typesCustomers = filterCustomers.map((item) => item.type);
    const filterTypesProducers = Array.from(new Set(typesProducers));
    const filterTypesCustomers = Array.from(new Set(typesCustomers));

    filterTypesProducers.forEach((item) => {
        let a = filterProducers.filter((el) => el.type === item);
        newFiltersProducers.push(a);
    });
    filterTypesCustomers.forEach((item) => {
        let a = filterCustomers.filter((el) => el.type === item);
        newFiltersCustomers.push(a);
    });

    state.filteredAggregatesCustomers = state.aggregatesCustomers;
    state.filteredAggregatesProducers = state.aggregatesProducers;

    // Hide aggregate
    if (filter.some((x) => x.type === 'aggregateHide')) {
        if (filter.some((x) => x.option && x.option.name === 'producersHide')) {
            state.filteredAggregatesProducers = [];
        }
        if (filter.some((x) => x.option && x.option.name === 'customersHide')) {
            state.filteredAggregatesCustomers = [];
        }
    }

    newFiltersProducers.forEach((filterItem) => {
        state.filteredAggregatesProducers = filterAggregates(state, state.aggregatesProducers, filterItem, state.filteredAggregatesProducers, filter.length);
    });
    newFiltersCustomers.forEach((filterItem) => {
        state.filteredAggregatesCustomers = filterAggregates(state, state.aggregatesCustomers, filterItem, state.filteredAggregatesCustomers, filter.length);
    });

    if (filter.length) {
        if (state.filteredAggregatesProducers && state.filteredAggregatesCustomers) {
			state.filteredAggregates = state.filteredAggregatesProducers.concat(state.filteredAggregatesCustomers);
        } else if (state.filteredAggregatesProducers || state.filteredAggregatesCustomers) {
            state.filteredAggregates = state.filteredAggregatesProducers ? state.filteredAggregatesProducers : state.filteredAggregatesCustomers;
        } else {
            state.filteredAggregates = [];
        }
    }

	if (state.selectedMarket) {
		let filteredMarkers = [];

        if (state.selectedMarket.type === 'customers' && state.selectedMarket.producers?.length) {
            filteredMarkers = state.filteredAggregatesProducers.filter((aggregate) => aggregate.type === 'producers' && state.selectedMarket.producers.includes(aggregate.id));
		} else if (state.selectedMarket.type === 'producers' && state.selectedMarket.customerPlants?.length) {
			filteredMarkers = state.filteredAggregatesCustomers.filter((aggregate) => aggregate.type === 'customers' && state.selectedMarket.customerPlants.includes(aggregate.id));
		}
		filteredMarkers.push(state.selectedMarket)
		state.filteredAggregates = filteredMarkers
	}

	if (!filter.length && !state.selectedMarket) {
		state.filteredAggregates = null;
		state.filteredAggregatesProducers = null;
		state.filteredAggregatesCustomers = null;
	}

    state.filterCount = filter.length;
    aggregatesLength(state);
};

export const setSelectedMarket = (state, market) => {
	state.selectedMarket = market;
}
