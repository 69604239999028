import Vue from 'vue';
import axios from 'axios';

export const getUserRole = ({ commit }, data) =>
	axios
		.post('account/getUserRole', data)
		.then((res) => {
			commit('setUserRole', res.data.userRole);
			commit('setUserMarketIds', [...res.data.contributor, ...res.data.owner]);
			commit('setUserOwnerMarketIds', res.data.owner);
			commit('setUserContributorMarketIds', res.data.contributor);
		})
		.catch((err) => {
			if (err) {
				Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
			}
		});

export const checkSubscriptionStatus = ({ commit }, data) =>
	axios
		.post('account/checkSubscriptionStatus', data)
		.then((res) => {
			commit('setSubscriptionStatus', res.data);
		})
		.catch((err) => {
			if (err) {
				Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
			}
		});

export const getCompanies = ({ commit }) =>
    axios
        .get('account/getCompanies')
        .then((res) => {
            commit('setCompanies', res.data);
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const createCompanyAccount = ({}, data) =>
    axios
        .post('account/createCompanyAccount', data)
        .then((res) => {
            return { userId: res.data.userId, companyId: res.data.companyId };
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const updateCompany = ({}, data) =>
    axios
        .post('account/updateCompany', data)
        .then(() => {
            return true;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const deleteCompany = ({}, data) =>
    axios
        .post('account/deleteCompany', data)
        .then(() => {
            return true;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const getUsers = ({ commit }, data) =>
    axios
        .get('account/getUsers')
        .then((res) => {
            commit('setUsers', res.data);
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const register = ({}, data) =>
    axios
        .post('account/register', data)
        .then((res) => {
            return res.data.userId;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const deleteUser = ({}, data) =>
	axios
		.post('account/deleteUser', data)
		.then(() => {
			return true;
		})
		.catch((err) => {
			if (err) {
				Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
			}
		});


export const editUser = ({}, data) =>
	axios
		.put('account/updateuser', data)
		.then(() => {
			return true;
		})
		.catch((err) => {
			if (err) {
				Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
			}
		});

export const updateUserStatus = ({}, data) =>
	axios
		.put('account/updateuserstatus', data)
		.then(() => {
			return true;
		})
		.catch((err) => {
			if (err) {
				Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
			}
		});

export const changePassword = ({ commit }, data) =>
    axios
        .post('account/changePassword', data)
        .then((res) => {
            return true;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });
