import store from '@/store';
import { UserRole } from '@/core/enums/userRole';

export const pageGuard = (to, from, next) => {
	const userRole = store.getters['account/userRole'];

	if (![UserRole.Administrator, UserRole.CompanyAdmin, UserRole.User].includes(userRole)) {
		next('/dashboard');
	} else {
		next();
	}
};
