import Vue from 'vue';
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import selectPlantTypeOptionById from '../../utils/multiselct';

export const getCountries = (state, countries) => {
    state.countries = countries;
};

export const localSave = (state, payload) => {
    localStorage.setItem(payload.type, JSON.stringify(payload.form));
};

export const setMarketId = (state, marketId) => {
    localStorage.setItem('marketId', marketId);
    state.marketId = marketId;
};

export const stepsDone = (state, payload) => {
    state.steps.forEach((step) => {
        if (step.name === payload.name) {
            step.done = payload.status;
        }
    });
};

export const stepsProcess = (state, payload) => {
    state.steps.forEach((step) => {
        if (step.name === payload.name) {
            step.process = payload.status;
        }
    });
};

export const addField = (state) => {
    state.groupMarketNames.push({
        name: 'n/a',
        color: '',
        newGroup: true
    });
};

export const setUpdateMarketName = (state, payload) => {};

export const getRemoveGroupMarket = (state, marketGroupId) => {
    state.groupMarketNames = state.groupMarketNames.filter((field) => field.id !== marketGroupId);
};

export const setGroupMarketId = (state, groupMarketId) => {
    const lastGroup = state.groupMarketNames[state.groupMarketNames.length - 1];
    if (lastGroup.name) {
        Vue.set(state.groupMarketNames[state.groupMarketNames.length - 1], 'id', groupMarketId);
    } else {
        Vue.set(state.groupMarketNames[state.groupMarketNames.length - 2], 'id', groupMarketId);
    }
    // Vue.set(state.groupMarketNames[state.groupMarketNames.length - 1], 'id', groupMarketId);
};

export const getLocalNameFields = (state, payload) => {
    state.groupMarketNames = payload;
};

export const getPlantTypes = (state, payload) => {
    const newOptions = payload.map((item) => ({
        ...item,
        selected: false,
    }));
    state.optionsPlantType = newOptions;
};

export const getGeologies = (state, payload) => {
    const newOptions = payload.map((item) => ({
        ...item,
        selected: false,
        geologySubTypes: item.geologySubTypes.map((el) => ({
            ...el,
            selected: false,
        })),
    }));
    state.optionsGeology = newOptions;
};

export const getSaveNewGeology = (state, geology) => {
    state.optionsGeology.forEach((item) => {
        if (item.id === geology.geologyTypeId) {
            const geo = {
                ...geology,
                selected: true,
            };
            item.geologySubTypes.push(geo);
        }
    });
};

export const getRemoveGeologySubType = (state, payload) => {
    state.optionsGeology.forEach((item) => {
        if (item.id === payload.optionId) {
            item.geologySubTypes = item.geologySubTypes.filter((el) => el.id !== payload.subOptionid);
        }
    });
};

export const getSaveIdPlantTypes = (state, payload) => {
    if (state.selectedPlantTypes.includes(payload.id)) {
        state.selectedPlantTypes = state.selectedPlantTypes.filter((id) => id !== payload.id);
    } else {
        state.selectedPlantTypes.push(payload.id);
    }
    state.optionsPlantType = selectPlantTypeOptionById(state.optionsPlantType, [payload.id]);
};

export const getLocalPlantTypes = (state, payload) => {
    if (payload) {
        state.optionsPlantType.forEach((option) => (option.selected = false));
        state.selectedPlantTypes = payload;
        state.optionsPlantType.forEach((option) => {
            payload.forEach((id) => {
                if (option.id === id) {
                    option.selected = true;
                }
            });
        });
    }
};

export const getLocalGeology = (state, payload) => {
    state.optionsGeology = payload;
};

export const setOptionGeology = (state, payload) => {
    state.optionsGeology.forEach((option) => {
        if (option.id === payload.id) {
            option.selected = !option.selected;
            option.geologySubTypes.forEach((el) => {
                el.selected = option.selected;
            });
        }
    });
};

export const getSubOptionGeology = (state, payload) => {
    state.optionsGeology.forEach((option) => {
        if (option.id === payload.option.id) {
            option.geologySubTypes.forEach((subOption) => {
                if (subOption.id === payload.subOption.id) {
                    subOption.selected = !subOption.selected;
                }
            });
        }
    });
};

export const selectedGeologyOptions = (state, payload) => {
    state.selectedGeologyOptions = payload;
};

export const setUpdateGeology = (state, payload) => {
    state.updateGeology = payload;
};

export const getProductTypes = (state, payload) => {
    state.productTypes = payload.map((item) => ({
        ...item,
        value: null,
    }));
};

export const saveStandartSplit = (state, payload) => {
    state.standartSplit = payload;
};

export const getTransportTypes = (state, transportTypes) => {
    state.transportTypes = transportTypes;
};

export const saveTransportMode = (state, localTransportMode) => {
    state.transportMode = localTransportMode;
};

export const newTransportMode = (state, payload) => {
    state.transportMode.push(payload);
};

export const addTransportMode = (state, marketTransportModeId) => {
    if (state.transportMode.length) {
        state.transportMode[state.transportMode.length - 1].marketTransportModeId = marketTransportModeId;
    }
};

export const removeTransportMode = (state, marketTransportModeId) => {
    // state.transportMode = state.transportMode.filter((item) => item.marketTransportModeId !== marketTransportModeId);
};

export const removeTransportModeByIndex = (state, index) => {
    state.transportMode.splice(index, 1);
};

export const getCurrencies = (state, currensies) => {
    state.currenciesList = currensies;
};

export const getMetrics = (state, metrics) => {
    state.metricsList = metrics;
};

const buildStandartSplit = (id, standartSplit, type) => {
    const result = {
        coarse: null,
        fillBase: null,
        fine: null,
        id,
        name: '',
        percent: null,
        type,
        volume: null,
    };
    standartSplit.forEach((item) => {
        switch (item.productTypeId) {
            case 1:
                if (item.displayName) {
                    result.name = item.displayName;
                }
                if (item.percent > 0) {
                    result.volume = String(item.percent);
                }
                break;
            case 2:
                result.fillBase = String(item.percent);
                break;
            case 3:
                result.fine = String(item.percent);
                break;
            case 4:
                result.coarse = String(item.percent);
                break;
            default:
                break;
        }
    });
    return result;
};

export const setMarket = (state, market) => {
    if (market.marketGroups.length) {
        state.groupMarketNames = market.marketGroups.map((item) => {
            return {
                id: item.id,
                name: item.group.name,
				isMyGroup: item.group.isMyGroup,
            };
        });
    }
    if (market.marketPlantTypes.length) {
        const plantTypes = market.marketPlantTypes.map((el) => el.plantType.id);
        getLocalPlantTypes(state, plantTypes);
    }
    if (market.marketGeologySubTypes.length) {
        state.optionsGeology.forEach((option) => option.geologySubTypes.forEach((subOption) => (subOption.selected = false)));
        const geology = [];
        const geologySub = [];
        state.optionsGeology.forEach((option) => {
            option.geologySubTypes.forEach((item) => {
                market.marketGeologySubTypes.forEach((op) => {
                    if (item.id === op.geologySubTypeId) {
                        geology.push(option);
                        geologySub.push(item);
                    }
                });
            });
        });
        for (let i = 0; i < market.marketGeologySubTypes.length; i++) {
            getSubOptionGeology(state, {
                option: geology[i],
                subOption: geologySub[i],
            });
        }
        const standartSplit = [];
        market.marketGeologySubTypes.forEach((option) => {
            standartSplit.push(buildStandartSplit(option.geologySubTypeId, option.productTypesStandartSplits, geologySub.find((item) => item.id === option.geologySubTypeId).name));
        });
        state.standartSplit = standartSplit;
    }
    if (market.marketTransortModes.length) {
        const transportMode = market.marketTransortModes.map((item) => {
            return {
                id: item.id,
                loadingCosts: String(item.loadingCost),
                marketTransportModeId: item.transportTypeId,
                roadTransCosts: String(item.costPerKm),
                type: item.transportType.name,
                unloadingCosts: String(item.unloadingCost),
            };
        });
        saveTransportMode(state, transportMode);
    }
    state.steps.forEach((step) => {
        if (step.name === 'Market Details') {
            step.done = true;
        }
    });
    if (market.boundary && market.boundary.coordinates.length) {
        state.steps.forEach((step) => {
            if (step.name === 'Boundary') {
                step.done = true;
            }
        });
    }
    if (market.marketGroups.length && market.marketPlantTypes.length && market.marketGeologySubTypes.length && state.standartSplit.length && state.transportMode.length) {
        state.steps.forEach((step) => {
            if (step.name === 'Market Settings') {
                step.done = true;
            }
        });
    } else if (market.marketGroups.length || market.marketPlantTypes.length || market.marketGeologySubTypes.length || state.standartSplit.length || state.transportMode.length) {
        state.steps.forEach((step) => {
            if (step.name === 'Market Settings') {
                step.process = true;
            }
        });
    }
    state.currentMarket = market;
};

export const removeSettings = (state) => {
    state.steps.forEach((step) => {
        step.process = false;
        step.done = false;
    });
    state.marketId = null;
    state.currentMarket = null;
    state.groupMarketNames = [
        {
            name: 'n/a',
            color: 'green',
            disabled: true,
        },
    ];
    state.selectedGeologyOptions = [];
    state.standartSplit = [];
    state.transportMode = [
        {
            marketTransportModeId: null,
            type: '',
            roadTransCosts: null,
            loadingCosts: null,
            unloadingCosts: null,
        },
    ];
};
