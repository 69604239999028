export const getSteps = (state) => state.steps;
export const getMarketId = (state) => state.marketId;
export const getGroupMarketNames = (state) => state.groupMarketNames;
export const getOptionsPlantType = (state) => state.optionsPlantType;
export const getOptionsGeology = (state) => state.optionsGeology;
export const getCountries = (state) => state.countries;
export const getSelectedPlantType = (state) => state.selectedPlantTypes;
export const getSelectedOptionsGeology = (state) => state.selectedOptionsGeology;
export const getSelectedGeologyOptions = (state) => state.selectedGeologyOptions;
export const getProductTypes = (state) => state.productTypes;
export const getStandartSplit = (state) => state.standartSplit;
export const updateGeology = (state) => state.updateGeology;
export const getTransportTypes = (state) => state.transportTypes;
export const getTransportMode = (state) => state.transportMode;
export const getCurrenciesList = (state) => state.currenciesList;
export const getMetricsList = (state) => state.metricsList;
export const getMarketSettings = (state) => state.marketSettings;

export const getMarket = (state) => state.currentMarket;
export const getMarketStandartSplit = (state) => state.currentMarketStandartSplit;

// export const getLocalSaveMarketDetails = (state) => state.marketDetails;
