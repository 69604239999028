import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

const state = {
    steps: [
        {
            name: 'Market Details',
            done: false,
            process: false,
        },
        {
            name: 'Boundary',
            done: false,
            process: false,
        },
        {
            name: 'Market Settings',
            done: false,
            process: false,
        },
    ],
    marketId: null,
    countries: [],
    optionsYear: [
        '2030',
        '2029',
        '2028',
        '2027',
        '2026',
        '2025',
        '2024',
        '2023',
        '2022',
        '2021',
        '2020',
        '2019',
        '2018',
        '2017',
        '2016',
        '2015',
        '2014',
        '2013',
        '2012',
        '2011',
        '2010',
        '2009',
        '2008',
        '2007',
        '2006',
        '2005',
        '2004',
        '2003',
        '2002',
        '2001',
        '2000',
    ],
    groupMarketNames: [
        {
            name: 'n/a',
            color: 'green',
            disabled: true,
        },
    ],
    optionsPlantType: [],
    optionsGeology: [],
    selectedPlantTypes: [],
    selectedGeology: [],
    selectedGeologyOptions: [],
    productTypes: [],
    standartSplit: [],
    updateGeology: false,
    currenciesList: [
        {
            id: 1,
            name: 'New Zealand dollar',
            img: './currency/nz.svg',
        },
        {
            id: 2,
            name: 'Australian dollar',
            img: './currency/au.svg',
        },
        {
            id: 3,
            name: 'Euro',
            img: './currency/eu.svg',
        },
        {
            id: 4,
            name: 'U.S. dollar',
            img: './currency/us.svg',
        },
    ],
    metrics: [
        {
          id: 1,
          name: "Metric",
          distance: "km",
          volume: "m3",
          mass: "kg"
        },
        {
          id: 2,
          name: "Imperial",
          distance: "mile",
          volume: "yd3",
          mass: "lb"
        }
    ],
    separatorOptios: [
        {
            id: 0,
            name: 'Decimal point 0.1',
        },
        {
            id: 1,
            name: 'Decimal comma 0,1',
        },
    ],    
    transportMode: [
        {
            marketTransportModeId: null,
            type: '',
            roadTransCosts: null,
            loadingCosts: null,
            unloadingCosts: null,
        },
    ],
    currentMarket: null,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
