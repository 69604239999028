export const setAuthenticate = (state, isAuthenticate) => {
  state.isAuthenticate = isAuthenticate;
};

export const setToken = (state, token) => {
  state.token = token;
};

export const setAuthenticateSource = (state, authenticateSource) => {
  state.authenticateSource = authenticateSource;
};

export const cancelAuthenticateSource = (state) => {
  state.authenticateSource.cancel();
};
