import Vue from 'vue';
import axios from 'axios';

export const getMarketBoundary = ({ commit }) =>
    axios
        .get(`markets/${Number(localStorage.getItem('marketId'))}/boundry`)
        .then((res) => {
            commit('setMarketBoundary', res.data);
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });
