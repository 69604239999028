export const setIsDisplayEditDataToolbar = (state, isDisplayEditDataToolbar) => {
  state.isDisplayEditDataToolbar = isDisplayEditDataToolbar;
};

export const setIsLoadingEditDataToolbar = (state, isLoadingEditDataToolbar) => {
  state.isLoadingEditDataToolbar = isLoadingEditDataToolbar;
};

export const setIsLoadingEditDataToolbarDuplicate = (state, isLoadingEditDataToolbarDuplicate) => {
  state.isLoadingEditDataToolbarDuplicate = isLoadingEditDataToolbarDuplicate;
};

export const setIsLoadingEditDataToolbarDelete = (state, isLoadingEditDataToolbarDelete) => {
  state.isLoadingEditDataToolbarDelete = isLoadingEditDataToolbarDelete;
};

export const setIsDisabledEditDataToolbarDuplicateButton = (state, isDisabledEditDataToolbarDuplicateButton) => {
  state.isDisabledEditDataToolbarDuplicateButton = isDisabledEditDataToolbarDuplicateButton;
};

export const setIsDisabledEditDataToolbarDeleteButton = (state, isDisabledEditDataToolbarDeleteButton) => {
  state.isDisabledEditDataToolbarDeleteButton = isDisabledEditDataToolbarDeleteButton;
};
