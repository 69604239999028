import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

const state = {
    isDisplayPaginationToolbar: false,
    isLoadingPaginationToolbar: false,
    isLoadingPaginationToolbarAddData: false,
    isDisabledPaginationToolbarAddDataButton: false,
    defaultPageSize: 15,
    pageSizeSteps: [15, 30, 45, 60, 120],
    pageSize: null,
    page: 1,
    totalPages: null,
    triggerAddData: false,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
