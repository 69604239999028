import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

const state = {
    userRole: null,
	subscriptionStatus: null,
    companies: [],
    users: [],
	userMarketIds: [],
	userOwnerMarketIds: [],
	userContributorMarketIds: [],
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
