export const MarketSegmentType = {
  ReadyMixConcrete: 0,
  Asphalt: 1,
  ConcreteProducts: 2,
  Railway: 3,
  OtherSegments: 4,
  Custom: 5,
  AdditionalOther: 6,
  CustomAdditionalOther: 7,
};
