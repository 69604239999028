import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';
import main from '@/views/Main.vue';

import { getMarketGuard } from './guards/getMarketGuard';
import { pageGuard } from './guards/pageGuard';
import {checkUserRole} from "@/router/guards/checkUserRole";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: '',
            meta: {
                public: false,
            },
            component: main,
            children: [
                {
                    path: '/',
                    name: 'marketAssessment',
                    beforeEnter: checkUserRole([], pageGuard),
                    meta: {
                        public: false,
                    },
                    component: () => import(/* webpackChunkName: "index" */ '../views/marketAssessment/index.vue'),
                },
                {
                    path: '/dashboard',
                    name: 'dashboard',
					beforeEnter: checkUserRole([]),
                    meta: {
                        public: false,
                    },
                    component: () => import(/* webpackChunkName: "index" */ '../views/dashboard/index.vue'),
                },
                {
                    path: '/general-settings',
                    name: 'generalSettings',
					beforeEnter: checkUserRole(['checkMarketOwner'], getMarketGuard),
                    meta: {
                        public: false,
                    },
                    component: () => import(/* webpackChunkName: "index" */ '../views/generalSettings/index.vue'),
                },
                {
                    path: '/aggregate-producers',
                    name: 'aggregateProducers',
					beforeEnter: checkUserRole(['checkMarketOwner'], pageGuard),
                    meta: {
                        public: false,
                    },
                    component: () => import(/* webpackChunkName: "index" */ '../views/aggregateProducers/index.vue'),
                },
                {
                    path: '/market-segments',
                    name: 'marketSegments',
					beforeEnter: checkUserRole(['checkMarketOwner'], pageGuard),
                    meta: {
                        public: false,
                    },
                    component: () => import(/* webpackChunkName: "index" */ '../views/marketSegment/index.vue'),
                },
                {
                    path: '/administration-users',
                    name: 'administrationUsers',
					beforeEnter: checkUserRole([], pageGuard),
                    meta: {
                        public: false,
                    },
                    component: () => import(/* webpackChunkName: "index" */ '../views/administrationUser/index.vue'),
                },
                {
                    path: '/administration-companies',
                    name: 'administrationCompanies',
					beforeEnter: checkUserRole([], pageGuard),
                    meta: {
                        public: false,
                    },
                    component: () => import(/* webpackChunkName: "index" */ '../views/administrationCompany/index.vue'),
                },
            ],
        },
        {
            path: '/login',
            name: 'login',
            meta: {
                public: true,
            },
            component: () => import(/* webpackChunkName: "Login" */ '../views/public/Login.vue'),
        },
        {
            path: '/forgot-password',
            name: 'forgotPassword',
            meta: {
                public: true,
            },
            component: () => import(/* webpackChunkName: "ResetPassword" */ '../views/public/ForgotPassword.vue'),
        },
        {
            path: '/recovery-password',
            name: 'recoveryPassword',
            meta: {
                public: true,
            },
            component: () => import(/* webpackChunkName: "RecoveryPassword" */ '../views/public/RecoveryPassword.vue'),
        },
        {
            path: '/update-browser',
            name: 'updateBrowser',
            meta: {
                public: true,
            },
            component: () => import(/* webpackChunkName: "UpdateBrowser" */ '../views/UpdateBrowser.vue'),
        },
		{
			path: '/page-not-found',
			name: 'pageNotFound',
			meta: {
				public: true,
			},
			component: () => import(/* webpackChunkName: "PageNotFound" */ '../views/PageNotFound.vue'),
		},
		{
			path: '/expired-subscription',
			name: 'expiredSubscription',
			beforeEnter: checkUserRole([]),
			meta: {
				public: true,
			},
			component: () => import(/* webpackChunkName: "PageNotFound" */ '../views/ExpiredSubscription.vue'),
		},
		{
			path: '/not-started-subscription',
			name: 'notStartedSubscription',
			beforeEnter: checkUserRole([]),
			meta: {
				public: true,
			},
			component: () => import(/* webpackChunkName: "PageNotFound" */ '../views/NotStartedSubscription.vue'),
		},
    ],
});

router.beforeEach(async (to, from, next) => {

    if (to.matched.length > 0) {
        if (to.matched.some((record) => !record.meta.public)) {

			await store.dispatch('account/checkSubscriptionStatus')
			const status = store.getters['account/subscriptionStatus']

			if (status.startDate){
				if (new Date(status.startDate) > new Date()){
					next({
						path: '/not-started-subscription',
						params: { nextUrl: to.fullPath },
					});
				}
			}

			if (status.isExpired && status.subscriptionType && store.getters['authenticate/token']){
				next({
					path: '/expired-subscription',
					params: { nextUrl: to.fullPath },
				});
			}

			if (store.getters['authenticate/token'] === null) {
                next({
                    path: '/login',
                    params: { nextUrl: to.fullPath },
                });
            } else {
                if (store.getters['authenticate/authenticateSource']) {
                    store.dispatch('authenticate/cancelRequests');
                }
                next();
            }
        } else {
            next();
        }
    } else {
        next({
            path: '/pageNotFound',
            params: { nextUrl: '/pageNotFound' },
        });
    }
});

export default router;
