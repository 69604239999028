import Vue from 'vue';

import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import * as Sentry from "@sentry/vue";

Vue.config.productionTip = false;

require('./configs/axios-config');

require('./configs/libs-config');

require('./configs/global-components-config');

require('./configs/global-directives-config');

Sentry.init({
  Vue,
  dsn: "https://37b9413f23b845799514627a991f180a@o4504526402420736.ingest.sentry.io/4505176030117888",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment:process.env.NODE_ENV
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');