export const setIsDataImporting = (state, data) => {
    state.isDataImporting = data;
};

export const setAggregatesProducerAddresses = (state, data) => {
    state.aggregatesProducerAddresses = data;
};

export const setCustomerAddresses = (state, data) => {
    state.customerAddresses = data;
};


export const setCustomerPlantNames = (state, data) => {
    state.customerPlantNames = data;
};
