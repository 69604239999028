import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

const state = {
  marketSegments: [],
  customMarketSegmentNames: [],
  companies: [],
  groups: [],
  plantTypes: [],
  tags: [],
  geologies: [],
  productTypes: [],
  availableSuppliers: [],
  customers: [],
	producersRate: null,
	overallProducersRate: null,

};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
