import Vue from 'vue';
import axios from 'axios';

export const forgotPassword = ({}, data) =>
    axios
        .post('account/forgotPassword', data)
        .then(() => {
            return true;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const verifyUserToken = ({}, data) =>
    axios
        .post('account/verifyUserToken', data)
        .then(() => {
            return true;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const resetPassword = ({}, data) =>
    axios
        .post('account/resetPassword', data)
        .then(() => {
            return true;
        })
        .catch((err) => {
            if (err) {
                if (err.data.errors) {
                    err.data.errors.forEach((error) => {
                        Vue.toasted.error(error.description[error.description.length - 1] === '.' ? error.description.slice(0, -1) : error.description);
                    });
                }
            }
        });
