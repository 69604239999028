import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

const state = {
  availableMarkets: [],
  isLoadedAvailableMarkets: false,
  markets: [],
  market: null,
  users: [],
  // marketContributors: [],
  // marketStatuses: [],
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
