const selectPlantTypeOptionById = (options, ids) => {
  const newOptions = [];
  ids.forEach((id) => {
    options.forEach((option) => {
      if (option.id === id) {
        newOptions.push({
          ...option,
          selected: !option.selected,
        });
      } else {
        newOptions.push({
          ...option,
        });
      }
    });
  });
  return newOptions;
};

export default selectPlantTypeOptionById;
