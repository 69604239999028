import config from '@/configs/map-config';
import axios from 'axios';

export const searchLocation = ({ commit }, search) =>
    axios.get(`${config.geocodingApi + search}.json?access_token=${config.accessToken}`).then((res) => commit('setLocations', res.data.features));

export const searchLocationWithResult = ({ commit }, search) =>
    axios.get(`${config.geocodingApi + search}.json?access_token=${config.accessToken}`).then((res) => {
        return res.data.features;
    });
