import Vue from 'vue';
import axios from 'axios';

const TOKEN = 'token';

export const login = ({ commit, dispatch }, data) =>
    axios
        .post('account/login', data)
        .then((res) => {
            localStorage.setItem(TOKEN, res.data.access_token);
            commit('setToken', res.data.access_token);
            commit('setAuthenticate', true);
			// dispatch('account/subscriptionStatus');
			dispatch('account/getUserRole');
            return true;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const logout = ({}) =>
    axios
        .post('account/logout')
        .then(() => {
            return true;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const cancelRequests = ({ commit }) => {
    commit('cancelAuthenticateSource');
    commit('setAuthenticateSource', axios.CancelToken.source());
};
