import Vue from 'vue';
import axios from 'axios';
import store from '@/store/index';

export const getDefaultOtherMarketSegments = ({ commit }, data) =>
    axios
        .post('suppliers/getDefaultOtherMarketSegments', data)
        .then((res) => {
            commit('setDefaultOtherMarketSegments', res.data.marketSegments);
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const getOtherMarketSegments = ({ commit }, data) =>
    axios
        .post('suppliers/getOtherMarketSegments', data)
        .then((res) => {
            commit('setOtherMarketSegments', res.data.marketSegments);
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const addOtherMarketSegment = ({ commit }, data) =>
    axios
        .post('suppliers/addOtherMarketSegment', data)
        .then((res) => {
            return res.data.marketSegment;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const createOtherMarketSegment = ({}, data) =>
    axios
        .post('suppliers/createOtherMarketSegment', data)
        .then((res) => {
            return res.data.marketSegment;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const deleteOtherMarketSegment = ({}, data) =>
    axios
        .post('suppliers/deleteOtherMarketSegment', data)
        .then((res) => {
            return true;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const saveOtherSegmentSupplier = ({}, data) =>
    axios
        .post('suppliers/saveOtherSegmentSupplier', data)
        .then((res) => {
            return res.data.otherSegmentSupplyId;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const getAggregatesProducers = ({ commit }, data) =>
    axios
        .post('suppliers/getAggregatesProducers', data)
        .then((res) => {
            commit('paginationToolbar/setTotalPages', res.data.pagesCount, { root: true });
            commit('setAggregateProducers', res.data.aggregatesProducers);
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const getAvailableAggregateProducers = async ({ commit }, data) => {
    const totalPage = store.getters['paginationToolbar/totalPages'];
    const suppliers = [];
    commit('setAggregateProducers', []);
    for (let page = 1; page <= totalPage; page++) {
        suppliers.push(
            axios.post('suppliers/getAggregatesProducers', { marketId: data.marketId, page }).then((res) => {
                commit('concatAggregateProducers', res.data.aggregatesProducers);
            }),
        );
    }
    await Promise.all(suppliers);
    // const totalPage = store.getters['paginationToolbar/totalPages'];
    // const page = store.getters['paginationToolbar/page'];
    // const suppliers = [];
    // for (let index = page + 1; index <= totalPage; index++) {
    //   suppliers.push(
    //     axios.post('suppliers/getAggregatesProducers', { marketId: data.marketId, page: index }).then((res) => {
    //       commit('concatAggregateProducers', res.data.aggregatesProducers);
    //     }),
    //   );
    // }
    // await Promise.all(suppliers);
};
