import { companies } from '../aggregatesProducers/getters';

const plantTypeColor = (shortName) => {
    switch (shortName) {
        case 'Rec. Facility':
            return 'color: #048628; background: #d3ebd9;';
        case 'Inactive':
        case 'Greenfield':
            return 'color: #000000; background: #d7d8d9;';
        case 'Sand pit':
        case 'Quarry':
            return 'color: #0B538C; background: #d6e2ee;';
        default:
            return 'color: #707070; background: #e9e9e9;';
    }
};

const buildPlantType = (plantTypes) => {
    const newPlantTypes = plantTypes.map((item) => {
        return {
            ...item,
            style: plantTypeColor(item.shortName),
        };
    });
    return newPlantTypes;
};

export const setPlantTypes = (state, plantTypes) => {
    state.plantType = buildPlantType(plantTypes);
};

export const setGeologies = (state, geologies) => {
    let subGeology = [];
    geologies.forEach((option) => option.geologySubTypes.forEach((subOption) => subGeology.push(subOption)));
    state.geologies = subGeology;
};

export const setApplications = (state, applications) => {
    state.applications = applications;
};

export const setProducersGroups = (state, group) => {
    state.producersGroups = group;
};

export const setCustomersGroups = (state, group) => {
    state.customersGroups = group;
};

export const setAggregatesProducerCompanies = (state, companies) => {
    state.aggregatesProducerCompanies = companies;
};

export const setCustomerCompanies = (state, companies) => {
    state.customerCompanies = companies;
};

export const setMarketSegments = (state, marketSegments) => {
    state.marketSegments = marketSegments.map((item) => {
        return {
            id: item.id,
            name: item.segment.displayName,
        };
    });
};

export const saveFilter = (state, filter) => {
    state.selectedFilters.push(filter);
};

export const deleteFilter = (state, filter) => {
	state.selectedFilters = state.selectedFilters.filter((item) => item.option.name !== filter.option.name || item.aggregate !== filter.aggregate);
};

export const removeFilter = (state, filter) => {
	state.selectedFilters = state.selectedFilters.filter((item) => item.option.name !== filter.option.name || item.aggregate === filter.aggregate);
};

export const deleteFilterByAggregate = (state, aggregate) => {
    state.selectedFilters = state.selectedFilters.filter((item) => item.aggregate !== aggregate);
};

export const clearFilter = (state) => {
    state.selectedFilters = [];
};
