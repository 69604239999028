<template>
    <section>
        <v-toolbar class="base-header-content" color="regalBlue">
            <v-toolbar-title>
                <v-tabs :value="currentTab" @change="onTabChange" background-color="regalBlue"
                    active-class="active-item" height="64" hide-slider show-arrows dark>
                    <v-tab @click="$router.push({ path: '/' })"
                        :class="{ 'pointer-events-none': !isLoadedAvailableMarkets }" ripple>
                        <img src="@/assets/TK_logo_light.svg" class="logo" />
                    </v-tab>
                    <v-tab style="cursor: default" ripple>
						<template v-if="isLoadedAvailableMarkets && market === null">No markets</template>
                        <template v-else>
                            <v-select @change="onSelectMarket" :value="market" :items="filterAvailableMarkets()"
                                :loading="!isLoadedAvailableMarkets" :color="currentTab === 0 ? 'mangoTango' : 'white'"
                                :class="{ 'pointer-events-none': !isLoadedAvailableMarkets }"
                                style="background: none !important; width: 100%; max-width: 210px; z-index: 10;"
                                class="market-selection ml-2" item-color="regalBlue" item-value="id" return-object
                                hide-details dense>
                                <template slot="selection" slot-scope="data">
                                    <div style="background: none !important; max-width: 180px" class="text-truncate">
                                        <span class="white--text">{{ data.item.name }}, {{ data.item.year }}</span>
                                    </div>
                                </template>
                                <template slot="item" slot-scope="data">
                                    <div style="max-width: 500px" class="text-truncate">
                                        <span>{{ data.item.name }}, {{ data.item.year }}</span>
                                    </div>
                                </template>
                            </v-select>
                        </template>
                    </v-tab>
                    <v-tab @click="$router.push({ name: 'dashboard' })"
                        :class="{ 'pointer-events-none': !isLoadedAvailableMarkets || market === null }" ripple>
                        <img v-if="currentTab === 2" src="@/assets/header/dashboard_selected.svg"
                            class="icon-header-width" />
                        <img v-else src="@/assets/header/dashboard.svg" class="icon-header-width" />
                        <span :class="[(currentTab === 2 ? 'mangoTango' : 'white') + '--text']" class="pl-2">
                            Dashboard
                        </span>
                    </v-tab>
                    <v-tab v-show="userRole === enumUserRole.CompanyAdmin || userRole === enumUserRole.Administrator || isMarketOwner"
                        @click="$router.push({ name: 'generalSettings' })"
                        :class="{ 'pointer-events-none': !isLoadedAvailableMarkets || market === null }" ripple>
                        <img v-if="currentTab === 3" src="@/assets/header/settings_selected.svg"
                            class="icon-header-width" />
                        <img v-else src="@/assets/header/settings.svg" class="icon-header-width" />
                        <span :class="[(currentTab === 3 ? 'mangoTango' : 'white') + '--text']" class="pl-2 ">
                            Settings
                        </span>
                    </v-tab>
                    <v-tab v-show="userRole === enumUserRole.CompanyAdmin || userRole === enumUserRole.Administrator || isMarketOwner"
                        @click="$router.push({ name: 'aggregateProducers' })"
                        :class="{ 'pointer-events-none': !isLoadedAvailableMarkets || market === null }" ripple>
                        <img v-if="currentTab === 4" src="@/assets/header/aggregate_producers_selected.svg"
                            class="icon-header-width" />
                        <img v-else src="@/assets/header/aggregate_producers.svg" class="icon-header-width" />
                        <span :class="[(currentTab === 4 ? 'mangoTango' : 'white') + '--text']" class="pl-2">
                            Aggregates Producers
                        </span>
                    </v-tab>
                    <v-tab v-show="userRole === enumUserRole.CompanyAdmin || userRole === enumUserRole.Administrator || isMarketOwner"
                        @click="$router.push({ name: 'marketSegments' })"
                        :class="{ 'pointer-events-none': !isLoadedAvailableMarkets || market === null }" ripple>
                        <img v-if="currentTab === 5" src="@/assets/header/market_segments_selected.svg"
                            class="icon-header-width" />
                        <img v-else src="@/assets/header/market_segments.svg" class="icon-header-width" />
                        <span :class="[(currentTab === 5 ? 'mangoTango' : 'white') + '--text']" class="pl-2">
                            Market Segments
                        </span>
                    </v-tab>
                    <v-tab v-show="userRole === enumUserRole.CompanyAdmin || userRole === enumUserRole.Administrator"
                        @click="$router.push({ name: 'administrationUsers' })"
                        :class="{ 'pointer-events-none': !isLoadedAvailableMarkets }" ripple>
                        <span :class="[(currentTab === 6 ? 'mangoTango' : 'white') + '--text']">
                            User Admin
                        </span>
                    </v-tab>
                    <v-tab v-show="userRole === enumUserRole.Administrator"
                        @click="$router.push({ name: 'administrationCompanies' })"
                        :class="{ 'pointer-events-none': !isLoadedAvailableMarkets }" ripple>
                        <span :class="[(currentTab === 7 ? 'mangoTango' : 'white') + '--text']">
                            Administration Companies
                        </span>
                    </v-tab>
                </v-tabs>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu v-model="applicationMenus" :nudge-width="100" close-on-content-click offset-y style="z-index: 99">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon>
                        <img v-if="applicationMenus" src="@/assets/header/applications_selected.svg"
                            style="width: 30px" />
                        <img v-else src="@/assets/header/applications.svg" style="width: 30px" />
                    </v-btn>
                </template>
                <v-card>
                    <v-container>
						<v-btn :href="priceBeeURL" target="_blank" text class="application-menu-btn">
							<span>
								<img src="@/assets/header/price_bee.svg" />
								<span class="application-menu-text">Price Bee</span>
							</span>
						</v-btn>
						<v-btn :href="guideMarketBeeURL" target="_blank" text class="application-menu-btn">
							<span>
								<img src="@/assets/header/guide_market_bee.svg" />
								<span class="application-menu-text">Guide Market Bee</span>
							</span>
						</v-btn>
<!--                        <v-row>-->
                            <!-- <v-col cols="4">
                <v-row class="justify-center">
                  <v-btn color="mangoTango" dark fab>AN</v-btn>
                </v-row>
                <v-row class="justify-center pt-4">
                  <span class="application-menu-text">App Name</span>
                </v-row>
              </v-col>
              <v-col cols="4">
                <v-row class="justify-center">
                  <v-btn color="pastelRed" dark fab>AN</v-btn>
                </v-row>
                <v-row class="justify-center pt-4">
                  <span class="application-menu-text">App Name</span>
                </v-row>
              </v-col> -->
<!--                        </v-row>-->
                        <!-- <v-row justify="center">
              <v-col cols="4">
                <v-row class="justify-center">
                  <v-btn color="supernova" dark fab>AN</v-btn>
                </v-row>
                <v-row class="justify-center pt-4">
                  <span class="application-menu-text">App Name</span>
                </v-row>
              </v-col>
              <v-col cols="4">
                <v-row class="justify-center">
                  <v-btn color="brilliantAzure" dark fab>AN</v-btn>
                </v-row>
                <v-row class="justify-center pt-4">
                  <span class="application-menu-text">App Name</span>
                </v-row>
              </v-col>
              <v-col cols="4">
                <v-row class="justify-center">
                  <v-btn color="ufoGreen" dark fab>AN</v-btn>
                </v-row>
                <v-row class="justify-center pt-4">
                  <span class="application-menu-text">App Name</span>
                </v-row>
              </v-col>
            </v-row> -->
                    </v-container>
                </v-card>
            </v-menu>
            <v-btn @click="onLogout()" icon>
                <img src="@/assets/header/log_out.svg" style="width: 25px" />
            </v-btn>
        </v-toolbar>
		<v-alert
			border="left"
			color="red"
			dismissible
			text
			type="warning"
			v-if="subscriptionExpired && this.isSubscriptionStatus.expirationDate && !this.isSubscriptionStatus.isExpired"
		>
			Your subscription expires in {{ timeDifference }} days
		</v-alert>
		<v-alert
			border="left"
			color="red"
			dismissible
			text
			type="warning"
			v-if="!this.isSubscriptionStatus.subscriptionType && this.isSubscriptionStatus.isExpired"
		>
			Your subscription has expired, please contact manager for prolongation
		</v-alert>
    </section>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

import { getIndexTabByRouteName } from '@/core/helpers/baseHeaderHelper';
import { UserRole } from '@/core/enums/userRole';
import {userMarketIds} from "@/store/account/getters";

export default {
    name: 'BaseHeader',
    data() {
        return {
            applicationMenus: false,
			timeDifference: '',
			subscriptionExpired: false,
			priceBeeURL: process.env.VUE_APP_PRICE_BEE_URL,
			guideMarketBeeURL: process.env.VUE_APP_GUIDE_MARKET_BEE_URL,

            enumUserRole: UserRole,
        };
    },
    mounted() {
        const routerName = this.$router.currentRoute.name;
        const index = getIndexTabByRouteName(routerName);
        this.setPreviousTab(index);
        this.setCurrentTab(index);

		setTimeout(async () => {
			this.goToNextMarket()
			this.subscriptionExpires()
		}, 1000);
    },
    watch: {
        userRole() {
            const routerName = this.$router.currentRoute.name;
            const index = getIndexTabByRouteName(routerName);
            this.setPreviousTab(index);
            this.setCurrentTab(index);
        },
        '$route.currentRoute.path': {
            handler() {
                this.onInitPaginationToolbar();
                this.onInitEditDataToolbar();
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        ...mapGetters('account', {
            userRole: 'userRole',
			userMarketIds: 'userMarketIds',
			subscriptionStatus: 'subscriptionStatus',
		}),
        ...mapGetters('baseHeaderTab', {
            currentTab: 'currentTab',
            previousTab: 'previousTab',
        }),
        ...mapGetters('marketAssessment', {
            availableMarkets: 'availableMarkets',
            isLoadedAvailableMarkets: 'isLoadedAvailableMarkets',
            market: 'market',
        }),
		marketId() {
			return this.$store.getters['marketAssessment/market']?.id;
		},
		isMarketOwner() {
			if(!this.userMarketIds.includes(parseInt(this.marketId))){
				this.userMarketIds.push(parseInt(this.marketId))
			}
			return this.userMarketIds.includes(parseInt(this.marketId));
		},
		isSubscriptionStatus() {
			return this.subscriptionStatus
		},
    },
    methods: {
        ...mapMutations('authenticate', {
            setToken: 'setToken',
            setAuthenticateSource: 'setAuthenticateSource',
        }),
        ...mapMutations('baseHeaderTab', {
            setCurrentTab: 'setCurrentTab',
            setPreviousTab: 'setPreviousTab',
        }),
        ...mapMutations('paginationToolbar', {
            initPagination: 'initPagination',
            setIsDisplayPaginationToolbar: 'setIsDisplayPaginationToolbar',
            setIsLoadingPaginationToolbar: 'setIsLoadingPaginationToolbar',
            setIsLoadingPaginationToolbarAddData: 'setIsLoadingPaginationToolbarAddData',
            setIsDisabledPaginationToolbarAddDataButton: 'setIsDisabledPaginationToolbarAddDataButton',
            setPageSize: 'setPageSize',
        }),
        ...mapMutations('editDataToolbar', {
            setIsDisplayEditDataToolbar: 'setIsDisplayEditDataToolbar',
            setIsLoadingEditDataToolbar: 'setIsLoadingEditDataToolbar',
            setIsLoadingEditDataToolbarDuplicate: 'setIsLoadingEditDataToolbarDuplicate',
            setIsLoadingEditDataToolbarDelete: 'setIsLoadingEditDataToolbarDelete',
            setIsDisabledEditDataToolbarDuplicateButton: 'setIsDisabledEditDataToolbarDuplicateButton',
            setIsDisabledEditDataToolbarDeleteButton: 'setIsDisabledEditDataToolbarDeleteButton',
        }),
        ...mapMutations('marketAssessment', {
            setAvailableMarkets: 'setAvailableMarkets',
            setIsLoadedAvailableMarkets: 'setIsLoadedAvailableMarkets',
            setMarket: 'setMarket',
        }),
        ...mapActions('authenticate', {
            cancelRequests: 'cancelRequests',
            logout: 'logout',
        }),

		subscriptionExpires() {
			const status = this.isSubscriptionStatus
			const currentDate = new Date();
			const endDate = new Date(status.expirationDate)
			const timeDifference = endDate.getTime() - currentDate.getTime();
			const daysDifference = timeDifference / (1000 * 3600 * 24);
			this.timeDifference = Math.ceil(daysDifference)
			this.subscriptionExpired = daysDifference < 30;
		},

		goToNextMarket() {
			const hasElementWithIdZero = this.availableMarkets.some((element) => element.status === 0);
			if(hasElementWithIdZero){
				if (this.market.status === 3){
					const availableMarket = this.availableMarkets
					let index = availableMarket.findIndex(item => item.id === this.market.id);
					if (this.availableMarkets.length === index + 1){
						index = -1
					}
					if (availableMarket[index + 1].status === 3){
						this.setMarket(availableMarket[index + 1]);
						this.goToNextMarket()
					} else {
						this.setMarket(availableMarket[index + 1]);
					}
				}
			} else {
				this.setMarket(null);
			}
		},
        onInitPaginationToolbar() {
            this.initPagination(false);
            this.setIsDisplayPaginationToolbar(false);
            this.setIsLoadingPaginationToolbar(false);
            this.setIsLoadingPaginationToolbarAddData(false);
            this.setIsDisabledPaginationToolbarAddDataButton(false);
        },
        onInitEditDataToolbar() {
            this.setIsDisplayEditDataToolbar(false);
            this.setIsLoadingEditDataToolbar(false);
            this.setIsLoadingEditDataToolbarDuplicate(false);
            this.setIsLoadingEditDataToolbarDelete(false);
            this.setIsDisabledEditDataToolbarDuplicateButton(false);
            this.setIsDisabledEditDataToolbarDeleteButton(false);
        },
        async onTabChange(clickedTab) {
            this.setCurrentTab(clickedTab);
            await this.$nextTick();
            if (clickedTab === 1) {
                this.setCurrentTab(this.previousTab);
            } else {
                this.setPreviousTab(this.currentTab);
            }
        },
        filterAvailableMarkets() {
			// return this.availableMarkets.filter((x) => (x.status === 3) === false || (this.market && x.id === this.market.id));
			return this.availableMarkets.filter((x) => (x.status === 3) === false);
        },
        onSelectMarket(data) {
            this.cancelRequests();
            this.setMarket(data);
            localStorage.setItem('newSettings', false);

			if (this.userRole !== this.enumUserRole.CompanyAdmin && this.userRole !== this.enumUserRole.Administrator && !this.isMarketOwner) {
				if (['/general-settings', '/aggregate-producers', '/market-segments'].includes(this.$route.path)) {
					this.$router.push('/dashboard');
				}
			}
        },
        onLogout() {
            this.$router.push({ name: 'login' });
            this.cancelRequests();
            this.logout();
            this.setToken(null);
            this.setAuthenticateSource(null);
            this.setCurrentTab(0);
            this.setPreviousTab(0);
            this.onInitPaginationToolbar();
            this.setPageSize(null);
            this.onInitEditDataToolbar();
            this.setAvailableMarkets([]);
            this.setIsLoadedAvailableMarkets(false);
            this.setMarket(null);
            localStorage.removeItem('token');
        },
    },
};
</script>

<style lang="scss" scoped>
.v-tab {
    max-width: 100%;
}

.logo {
    width: 120px;
}

.active-item.v-tab--active {
    border-bottom: 8px solid #ff893a !important;
}

.active-item {
    background-color: #264662;
}

.application-menu-text {
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #0E3F66;
	display: block;
	margin-top: 2px;
}

.application-menu-btn {
	padding: 5px 10px 15px 10px !important;
	display: block;
	height: auto !important;
	flex-direction: column !important;
	border-radius: 5px;
	&:hover{
		background-color: #DBEDFF;
	}
	span {
		text-align: center;
	}
}

</style>
