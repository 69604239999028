import Vue from 'vue';
import axios from 'axios';
import store from '@/store/index';

export const getDefaultCompetitivePositionMarketSegments = ({ commit }, data) =>
    axios
        .post('competitors/getDefaultCompetitivePositionMarketSegments', data)
        .then((res) => {
            commit('setDefaultCompetitivePositionMarketSegments', res.data.marketSegments);
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const getCompetitivePositionMarketSegments = ({ commit }, data) =>
    axios
        .post('competitors/getCompetitivePositionMarketSegments', data)
        .then((res) => {
            commit('setCompetitivePositionMarketSegments', res.data.marketSegments);
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const addCompetitivePositionMarketSegment = ({}, data) =>
    axios
        .post('competitors/addCompetitivePositionMarketSegment', data)
        .then((res) => {
            return res.data.marketSegment;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const updateValueCompetitivePositionMarketSegment = ({}, data) =>
    axios
        .post('competitors/updateValueCompetitivePositionMarketSegment', data)
        .then(() => {
            return true;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const createCompetitivePositionMarketSegment = ({}, data) =>
    axios
        .post('competitors/createCompetitivePositionMarketSegment', data)
        .then((res) => {
            return res.data.marketSegment;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const deleteCompetitivePositionMarketSegment = ({}, data) =>
    axios
        .post('competitors/deleteCompetitivePositionMarketSegment', data)
        .then((res) => {
            return true;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const saveCompetitivePositionSegmentSupplier = ({}, data) =>
    axios
        .post('competitors/saveCompetitivePositionSegmentSupplier', data)
        .then((res) => {
            return res.data.competitivePositionSegmentSupplyId;
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const getCompetitorProducers = ({ commit }, data) =>
    axios
        .post('competitors/getCompetitorProducers', data)
        .then((res) => {
            commit('paginationToolbar/setTotalPages', res.data.pagesCount, { root: true });
            commit('setCompetitorProducers', res.data.competitorProducers);
        })
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });

export const getAvailableCompetitorProducers = async ({ commit }, data) => {
    const totalPage = store.getters['paginationToolbar/totalPages'];
    const competitors = [];
    commit('setCompetitorProducers', []);
    for (let page = 1; page <= totalPage; page++) {
        competitors.push(
            axios.post('competitors/getCompetitorProducers', { marketId: data.marketId, originalMarketSegmentId: data.originalMarketSegmentId, page }).then((res) => {
                commit('concatCompetitorProducers', res.data.competitorProducers);
            }),
        );
    }
    await Promise.all(competitors);
    // const totalPage = store.getters['paginationToolbar/totalPages'];
    // const page = store.getters['paginationToolbar/page'];
    // const competitors = [];
    // for (let index = page + 1; index <= totalPage; index++) {
    //   competitors.push(
    //     axios.post('competitors/getCompetitorProducers', { marketId: data.marketId, page: index }).then((res) => {
    //       commit('concatCompetitorProducers', res.data.competitorProducers);
    //     }),
    //   );
    // }
    // await Promise.all(competitors);
};
