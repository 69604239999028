export const setMarketSegments = (state, marketSegments) => {
  state.marketSegments = marketSegments;
};

export const setCustomMarketSegmentNames = (state, customMarketSegmentNames) => {
  state.customMarketSegmentNames = customMarketSegmentNames;
};

export const updateMarketSegmentValueByIndex = (state, data) => {
  state.marketSegments[data.index].tonsOfAggregatesPerUnit = data.tonsOfAggregatesPerUnit;
  state.marketSegments[data.index].coarseAggregatesPercent = data.coarseAggregatesPercent;
  state.marketSegments[data.index].fineAggregatesPercent = data.fineAggregatesPercent;
  state.marketSegments[data.index].contentPerUnit = data.contentPerUnit;
};

export const setCompanies = (state, companies) => {
  state.customers = companies;
};

export const setGroups = (state, groups) => {
  state.groups = groups;
};

export const setPlantTypes = (state, plantTypes) => {
  state.plantTypes = plantTypes;
};

export const setTags = (state, tags) => {
  state.tags = tags;
};

export const setGeologies = (state, geologies) => {
  state.geologies = geologies;
};

export const setProductTypes = (state, productTypes) => {
  state.productTypes = productTypes;
};

export const setAvailableSuppliers = (state, availableSuppliers) => {
  state.availableSuppliers = availableSuppliers;
};

export const setCustomers = (state, customers) => {
  state.customers = customers;
};

export const setSegmentsRate = (state, producersRate) => {
	state.producersRate = producersRate;
};

export const setLevelOfConfidence = (state, levelOfConfidence) => {
	state.levelOfConfidence = levelOfConfidence;
};

export const setOverallSegmentsRate = (state, overallProducersRate) => {
	state.overallProducersRate = overallProducersRate;
};
