<template>
    <v-app>
        <slot name="header" v-if="!isPublic">
            <base-header />
        </slot>

        <v-main>
            <v-container pa-8 fluid :style="{ 'background-color': backgroundColor }" :class="{ 'fill-height': isFillHeight }">
                <slot />
                <div v-if="this.token">
                    <pagination-toolbar />
                </div>
            </v-container>
        </v-main>

        <slot name="footer" v-if="isShowFooter">
            <base-footer />
        </slot>
    </v-app>
</template>

<script>
import PaginationToolbar from '@/components/paginationToolbar/PaginationToolbar.vue';
import BaseHeader from './BaseHeader.vue';
import BaseFooter from './BaseFooter.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'BaseLayout',
    components: {
        PaginationToolbar,
        BaseHeader,
        BaseFooter,
    },
    data() {
        return {};
    },
    props: {
        public: {
            type: Boolean,
            default: false,
        },
        fillHeight: {
            type: Boolean,
            default: false,
        },
        showHeader: {
            type: Boolean,
            default: true,
        },
        showFooter: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters('authenticate', {
            token: 'token',
        }),
        backgroundColor() {
            return this.$vuetify.theme.dark ? this.$vuetify.theme.themes.dark.whiteLilac : this.$vuetify.theme.themes.light.whiteLilac;
        },
        isPublic() {
            return this.public;
        },
        isFillHeight() {
            return this.fillHeight;
        },
        isShowHeader() {
            return this.showHeader;
        },
        isShowFooter() {
            return this.showFooter;
        },
    },
};
</script>

<style lang="scss" scoped></style>
