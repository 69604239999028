import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

const state = {
    marketId: null,
    plantTypes: [],
    groups: [],
    geologies: [],
    applications: [],
    productTypes: [],
    aggregatesProducers: [],
    transportTypes: [],
    tags: [],
    companies: [],
	producersRate: null,
    showAddProducer: false,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
