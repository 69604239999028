import store from '@/store';

export const getMarketGuard = (to, from, next) => {
    // const marketId = Number(localStorage.getItem('marketId'));
    store.dispatch('generalSettings/getPlantTypes');
    store
        .dispatch('generalSettings/getGeologies')
        .then(next)
        .catch((err) => {
            if (err) {
                Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
            }
        });
};
