export const marketSegments = (state) => state.marketSegments;
export const customMarketSegmentNames = (state) => state.customMarketSegmentNames;
export const companies = (state) => state.companies;
export const groups = (state) => state.groups;
export const plantTypes = (state) => state.plantTypes;
export const tags = (state) => state.tags;
export const geologies = (state) => state.geologies;
export const productTypes = (state) => state.productTypes;
export const availableSuppliers = (state) => state.availableSuppliers;
export const customers = (state) => state.customers;
export const producersRate = (state) => state.producersRate;
export const levelOfConfidence = (state) => state.levelOfConfidence;
export const overallProducersRate = (state) => state.overallProducersRate;
